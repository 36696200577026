// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class BridgingChallenge {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BridgingChallenge {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBridgingChallenge(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingChallenge,
  ): BridgingChallenge {
    return (obj || new BridgingChallenge()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBridgingChallenge(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingChallenge,
  ): BridgingChallenge {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BridgingChallenge()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  challenge(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  challengeLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  challengeArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  hash(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  hashLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  hashArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  static startBridgingChallenge(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addChallenge(
    builder: flatbuffers.Builder,
    challengeOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, challengeOffset, 0);
  }

  static createChallengeVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startChallengeVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(1, numElems, 1);
  }

  static addHash(builder: flatbuffers.Builder, hashOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, hashOffset, 0);
  }

  static createHashVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startHashVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(1, numElems, 1);
  }

  static endBridgingChallenge(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // challenge
    builder.requiredField(offset, 6); // hash
    return offset;
  }

  static createBridgingChallenge(
    builder: flatbuffers.Builder,
    challengeOffset: flatbuffers.Offset,
    hashOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    BridgingChallenge.startBridgingChallenge(builder);
    BridgingChallenge.addChallenge(builder, challengeOffset);
    BridgingChallenge.addHash(builder, hashOffset);
    return BridgingChallenge.endBridgingChallenge(builder);
  }
}
