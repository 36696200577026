export class BufferedQueue<T> {
  #maxSize: number;
  #maxTime: number;
  #queue: T[];

  #onFlush: (items: T[]) => void;

  #timeout: Timeout | null = null;

  constructor({
    maxSize = 8,
    maxTime = 250,
    onFlush,
  }: {
    maxSize?: number;
    maxTime?: number;
    onFlush: (items: T[]) => Promise<void> | void;
  }) {
    this.#maxSize = maxSize;
    this.#maxTime = maxTime;
    this.#queue = [];

    this.#onFlush = onFlush;
  }

  add(item: T) {
    this.#queue.push(item);

    if (this.#queue.length >= this.#maxSize) {
      if (this.#timeout !== null) {
        clearTimeout(this.#timeout);
      }

      this.flush();
    } else if (this.#timeout === null) {
      this.#timeout = setTimeout(() => {
        this.flush();
      }, this.#maxTime);
    }
  }

  async flush() {
    this.#timeout = null;

    const items = this.#queue;
    this.#queue = [];

    await this.#onFlush(items);
  }
}
