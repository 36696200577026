// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class BridgingPublicKey {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BridgingPublicKey {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBridgingPublicKey(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingPublicKey,
  ): BridgingPublicKey {
    return (obj || new BridgingPublicKey()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBridgingPublicKey(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingPublicKey,
  ): BridgingPublicKey {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BridgingPublicKey()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  publicKey(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  publicKeyLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  publicKeyArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  static startBridgingPublicKey(builder: flatbuffers.Builder) {
    builder.startObject(1);
  }

  static addPublicKey(
    builder: flatbuffers.Builder,
    publicKeyOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, publicKeyOffset, 0);
  }

  static createPublicKeyVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startPublicKeyVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(1, numElems, 1);
  }

  static endBridgingPublicKey(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // public_key
    return offset;
  }

  static createBridgingPublicKey(
    builder: flatbuffers.Builder,
    publicKeyOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    BridgingPublicKey.startBridgingPublicKey(builder);
    BridgingPublicKey.addPublicKey(builder, publicKeyOffset);
    return BridgingPublicKey.endBridgingPublicKey(builder);
  }
}
