import { stringify as bytesToUuid } from "uuid";

import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import type { User } from "@/feature-auth/models/user-model.mjs";
import { authTokenHandler } from "@/feature-auth/utils/auth-token-handler.mjs";
import bridge from "@/feature-bridge/bridge.mjs";
import { BridgeDeviceInfoData } from "@/feature-bridge/bridge-device.mjs";
import type {
  BridgeMessage,
  IpcConnection,
} from "@/feature-bridge/connection.mjs";
import { IpcConnectionBuilder } from "@/feature-bridge/connection.mjs";
import { devDebug } from "@/util/dev.mjs";

const IPC_DEVICE_INFO_CHANNEL = "ipc/device_info";

const SYNC_AUTH_TOKEN_CHANNEL = "app/auth";
const SYNC_USER_CHANNEL = "app/user";

let overlayHeartbeatTimeout: NodeJS.Timeout | null = null;

let ipcConnectionBuilder: IpcConnectionBuilder | null = null;
let ipcConnection: IpcConnection | null = null;

export async function handleBridgeIpcMessage(message: BridgeMessage) {
  // The overlay heartbeat channel is special and isn't visible through the "data" events
  if (message.channel === "overlay/heartbeat") {
    clearTimeout(overlayHeartbeatTimeout);
    overlayHeartbeatTimeout = null;

    const deviceId: string = bytesToUuid(message.data);

    if (ipcConnectionBuilder !== null) {
      if (ipcConnectionBuilder.deviceId !== deviceId) {
        ipcConnectionBuilder = null;
      }
    }

    if (ipcConnection !== null) {
      if (ipcConnection.device.deviceId !== deviceId) {
        await onOverlayExited();
      }
    }

    if (ipcConnectionBuilder === null && ipcConnection === null) {
      await onOverlayStarted(deviceId);
    }

    overlayHeartbeatTimeout = setTimeout(onOverlayExited, 5000);
  } else if (message.channel === IPC_DEVICE_INFO_CHANNEL) {
    onReceiveDeviceInfo(message.data);
  } else {
    ipcConnection?.emit("data", message);
  }
}

async function onOverlayStarted(deviceId: string) {
  devDebug("[bridge-ipc-handler] Detected overlay started");

  ipcConnectionBuilder = new IpcConnectionBuilder(deviceId);

  // Send an empty message over the `IPC_DEVICE_INFO_CHANNEL` so that it will reply with its device info
  await blitzMessage(EVENTS.BRIDGE_IPC_MESSAGE, {
    channel: IPC_DEVICE_INFO_CHANNEL,
    data: new Uint8Array(0),
  });
}

export function notifyOverlayOfUserChange(next?: User) {
  if (ipcConnection === null) {
    return;
  }

  devDebug("[bridge-ipc-handler] Detected user change");

  authTokenHandler.getToken().then(sendAuthTokenToOverlay);
  sendUserToOverlay(next);
}

async function onReceiveDeviceInfo(data: Uint8Array) {
  if (ipcConnectionBuilder === null) {
    devDebug("[bridge-ipc-handler] Received device info before heartbeat");

    return;
  }

  const deviceInfo = BridgeDeviceInfoData.parse(data);

  devDebug("[bridge-ipc-handler] Received overlay device info", deviceInfo);

  ipcConnection = ipcConnectionBuilder.finalize({
    deviceId: deviceInfo.deviceId,
    name: deviceInfo.name,
    platform: deviceInfo.platform,
    operatingSystem: deviceInfo.operatingSystem,
  });

  ipcConnectionBuilder = null;

  // Once we have a valid connection, send over the auth and user info
  await sendAuthTokenToOverlay(await authTokenHandler.getToken());
  await sendUserToOverlay(readState.user);

  ipcConnection.on("close", onOverlayExited);

  bridge.addConnection(ipcConnection);
}

function onOverlayExited() {
  if (ipcConnection === null) {
    return;
  }

  devDebug("[bridge-ipc-handler] Detected overlay exited");

  ipcConnection.off("close", onOverlayExited);

  ipcConnection.close();
  ipcConnection = null;
}

async function sendAuthTokenToOverlay(token: string | null) {
  if (!ipcConnection) {
    return;
  }

  devDebug("[bridge-ipc-handler] Sending auth token to overlay");

  await ipcConnection.send(
    SYNC_AUTH_TOKEN_CHANNEL,
    new TextEncoder().encode(JSON.stringify(token)),
  );
}

async function sendUserToOverlay(user?: User) {
  if (!ipcConnection) {
    return;
  }

  devDebug("[bridge-ipc-handler] Sending current user to overlay");

  await ipcConnection.send(
    SYNC_USER_CHANNEL,
    new TextEncoder().encode(JSON.stringify(user ?? null)),
  );
}
