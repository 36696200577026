// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

import {
  BridgingStep,
  unionListToBridgingStep,
  unionToBridgingStep,
} from "@/feature-bridge/idl/bridging/bridging-step.mjs";

export class BridgingMessage {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BridgingMessage {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBridgingMessage(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingMessage,
  ): BridgingMessage {
    return (obj || new BridgingMessage()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBridgingMessage(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingMessage,
  ): BridgingMessage {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BridgingMessage()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  stepType(): BridgingStep {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb_pos + offset)
      : BridgingStep.NONE;
  }

  step<T extends flatbuffers.Table>(obj: any): any | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
  }

  static startBridgingMessage(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addStepType(builder: flatbuffers.Builder, stepType: BridgingStep) {
    builder.addFieldInt8(0, stepType, BridgingStep.NONE);
  }

  static addStep(builder: flatbuffers.Builder, stepOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, stepOffset, 0);
  }

  static endBridgingMessage(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static finishBridgingMessageBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset);
  }

  static finishSizePrefixedBridgingMessageBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset, undefined, true);
  }

  static createBridgingMessage(
    builder: flatbuffers.Builder,
    stepType: BridgingStep,
    stepOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    BridgingMessage.startBridgingMessage(builder);
    BridgingMessage.addStepType(builder, stepType);
    BridgingMessage.addStep(builder, stepOffset);
    return BridgingMessage.endBridgingMessage(builder);
  }
}
