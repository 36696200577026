// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

import { BridgeDeviceId } from "@/feature-bridge/idl/bridge_device/bridge-device-id.mjs";
import { BridgeDevicePlatform } from "@/feature-bridge/idl/bridge_device/bridge-device-platform.mjs";

export class BridgeDeviceInfo {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BridgeDeviceInfo {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBridgeDeviceInfo(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgeDeviceInfo,
  ): BridgeDeviceInfo {
    return (obj || new BridgeDeviceInfo()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBridgeDeviceInfo(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgeDeviceInfo,
  ): BridgeDeviceInfo {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BridgeDeviceInfo()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  deviceId(obj?: BridgeDeviceId): BridgeDeviceId | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? (obj || new BridgeDeviceId()).__init(this.bb_pos + offset, this.bb!)
      : null;
  }

  name(): string | null;
  name(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  name(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  platform(): BridgeDevicePlatform {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset
      ? this.bb!.readUint8(this.bb_pos + offset)
      : BridgeDevicePlatform.Unknown;
  }

  operatingSystem(): string | null;
  operatingSystem(
    optionalEncoding: flatbuffers.Encoding,
  ): string | Uint8Array | null;
  operatingSystem(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  static startBridgeDeviceInfo(builder: flatbuffers.Builder) {
    builder.startObject(4);
  }

  static addDeviceId(
    builder: flatbuffers.Builder,
    deviceIdOffset: flatbuffers.Offset,
  ) {
    builder.addFieldStruct(0, deviceIdOffset, 0);
  }

  static addName(builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, nameOffset, 0);
  }

  static addPlatform(
    builder: flatbuffers.Builder,
    platform: BridgeDevicePlatform,
  ) {
    builder.addFieldInt8(2, platform, BridgeDevicePlatform.Unknown);
  }

  static addOperatingSystem(
    builder: flatbuffers.Builder,
    operatingSystemOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(3, operatingSystemOffset, 0);
  }

  static endBridgeDeviceInfo(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // device_id
    builder.requiredField(offset, 6); // name
    builder.requiredField(offset, 10); // operating_system
    return offset;
  }

  static createBridgeDeviceInfo(
    builder: flatbuffers.Builder,
    deviceIdOffset: flatbuffers.Offset,
    nameOffset: flatbuffers.Offset,
    platform: BridgeDevicePlatform,
    operatingSystemOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    BridgeDeviceInfo.startBridgeDeviceInfo(builder);
    BridgeDeviceInfo.addDeviceId(builder, deviceIdOffset);
    BridgeDeviceInfo.addName(builder, nameOffset);
    BridgeDeviceInfo.addPlatform(builder, platform);
    BridgeDeviceInfo.addOperatingSystem(builder, operatingSystemOffset);
    return BridgeDeviceInfo.endBridgeDeviceInfo(builder);
  }
}
