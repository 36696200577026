// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class BridgingAnswer {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BridgingAnswer {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBridgingAnswer(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingAnswer,
  ): BridgingAnswer {
    return (obj || new BridgingAnswer()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBridgingAnswer(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingAnswer,
  ): BridgingAnswer {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BridgingAnswer()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  result(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  resultLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  resultArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  static startBridgingAnswer(builder: flatbuffers.Builder) {
    builder.startObject(1);
  }

  static addResult(
    builder: flatbuffers.Builder,
    resultOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, resultOffset, 0);
  }

  static createResultVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startResultVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(1, numElems, 1);
  }

  static endBridgingAnswer(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // result
    return offset;
  }

  static createBridgingAnswer(
    builder: flatbuffers.Builder,
    resultOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    BridgingAnswer.startBridgingAnswer(builder);
    BridgingAnswer.addResult(builder, resultOffset);
    return BridgingAnswer.endBridgingAnswer(builder);
  }
}
