// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import { DiscoveryAdvertisement } from "@/feature-bridge/idl/discovery_packet/discovery-advertisement.mjs";
import { DiscoveryCandidate } from "@/feature-bridge/idl/discovery_packet/discovery-candidate.mjs";
import { DiscoveryInquiry } from "@/feature-bridge/idl/discovery_packet/discovery-inquiry.mjs";
import { DiscoveryInquiryReply } from "@/feature-bridge/idl/discovery_packet/discovery-inquiry-reply.mjs";
import { DiscoveryProbe } from "@/feature-bridge/idl/discovery_packet/discovery-probe.mjs";
import { DiscoverySignal } from "@/feature-bridge/idl/discovery_packet/discovery-signal.mjs";

export enum DiscoveryPacket {
  NONE = 0,
  Advertisement = 1,
  Probe = 2,
  Inquiry = 3,
  InquiryReply = 4,
  Signal = 5,
  Candidate = 6,
}

export function unionToDiscoveryPacket(
  type: DiscoveryPacket,
  accessor: (
    obj:
      | DiscoveryAdvertisement
      | DiscoveryCandidate
      | DiscoveryInquiry
      | DiscoveryInquiryReply
      | DiscoveryProbe
      | DiscoverySignal,
  ) =>
    | DiscoveryAdvertisement
    | DiscoveryCandidate
    | DiscoveryInquiry
    | DiscoveryInquiryReply
    | DiscoveryProbe
    | DiscoverySignal
    | null,
):
  | DiscoveryAdvertisement
  | DiscoveryCandidate
  | DiscoveryInquiry
  | DiscoveryInquiryReply
  | DiscoveryProbe
  | DiscoverySignal
  | null {
  switch (DiscoveryPacket[type]) {
    case "NONE":
      return null;
    case "Advertisement":
      return accessor(new DiscoveryAdvertisement())! as DiscoveryAdvertisement;
    case "Probe":
      return accessor(new DiscoveryProbe())! as DiscoveryProbe;
    case "Inquiry":
      return accessor(new DiscoveryInquiry())! as DiscoveryInquiry;
    case "InquiryReply":
      return accessor(new DiscoveryInquiryReply())! as DiscoveryInquiryReply;
    case "Signal":
      return accessor(new DiscoverySignal())! as DiscoverySignal;
    case "Candidate":
      return accessor(new DiscoveryCandidate())! as DiscoveryCandidate;
    default:
      return null;
  }
}

export function unionListToDiscoveryPacket(
  type: DiscoveryPacket,
  accessor: (
    index: number,
    obj:
      | DiscoveryAdvertisement
      | DiscoveryCandidate
      | DiscoveryInquiry
      | DiscoveryInquiryReply
      | DiscoveryProbe
      | DiscoverySignal,
  ) =>
    | DiscoveryAdvertisement
    | DiscoveryCandidate
    | DiscoveryInquiry
    | DiscoveryInquiryReply
    | DiscoveryProbe
    | DiscoverySignal
    | null,
  index: number,
):
  | DiscoveryAdvertisement
  | DiscoveryCandidate
  | DiscoveryInquiry
  | DiscoveryInquiryReply
  | DiscoveryProbe
  | DiscoverySignal
  | null {
  switch (DiscoveryPacket[type]) {
    case "NONE":
      return null;
    case "Advertisement":
      return accessor(
        index,
        new DiscoveryAdvertisement(),
      )! as DiscoveryAdvertisement;
    case "Probe":
      return accessor(index, new DiscoveryProbe())! as DiscoveryProbe;
    case "Inquiry":
      return accessor(index, new DiscoveryInquiry())! as DiscoveryInquiry;
    case "InquiryReply":
      return accessor(
        index,
        new DiscoveryInquiryReply(),
      )! as DiscoveryInquiryReply;
    case "Signal":
      return accessor(index, new DiscoverySignal())! as DiscoverySignal;
    case "Candidate":
      return accessor(index, new DiscoveryCandidate())! as DiscoveryCandidate;
    default:
      return null;
  }
}
