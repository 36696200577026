import * as flatbuffers from "flatbuffers";

import { BridgeDeviceInfoData } from "@/feature-bridge/bridge-device.mjs";
import * as idl from "@/feature-bridge/idl/bridging/bridging.mjs";

export abstract class BridgingStep {
  #type: idl.BridgingStep;

  constructor(type: idl.BridgingStep) {
    this.#type = type;
  }

  static parse(bytes: Uint8Array) {
    const bridgingStep = idl.BridgingMessage.getRootAsBridgingMessage(
      new flatbuffers.ByteBuffer(bytes),
    );

    const stepType = bridgingStep.stepType();

    const step = idl.unionToBridgingStep(stepType, (...args) => {
      return bridgingStep.step(...args);
    });

    if (step instanceof idl.BridgingHandshake) {
      return BridgingStepHandshake.from(step);
    } else if (step instanceof idl.BridgingUserConfirmation) {
      return BridgingStepUserConfirmation.from(step);
    } else if (step instanceof idl.BridgingAuthorized) {
      return BridgingStepAuthorized.from(step);
    } else if (step instanceof idl.BridgingPublicKey) {
      return BridgingStepPublicKey.from(step);
    } else if (step instanceof idl.BridgingChallenge) {
      return BridgingStepChallenge.from(step);
    } else if (step instanceof idl.BridgingAnswer) {
      return BridgingStepAnswer.from(step);
    }

    throw new Error("Unknown bridging step");
  }

  asBytes(): Uint8Array {
    const builder = new flatbuffers.Builder();

    builder.finish(
      idl.BridgingMessage.createBridgingMessage(
        builder,
        this.#type,
        this.finish(builder),
      ),
    );

    return builder.asUint8Array();
  }

  abstract finish(builder: flatbuffers.Builder): number;
}

export class BridgingStepHandshake extends BridgingStep {
  userId: string;

  deviceInfo: BridgeDeviceInfoData;

  constructor(props: NonMethods<BridgingStepHandshake>) {
    super(idl.BridgingStep.Handshake);

    Object.assign(this, props);
  }

  static from(handshake: idl.BridgingHandshake): BridgingStepHandshake {
    const deviceInfo = handshake.deviceInfo();

    if (deviceInfo === null) {
      throw new Error("Handshake contains invalid device info");
    }

    return new BridgingStepHandshake({
      userId: handshake.userId(),

      deviceInfo: BridgeDeviceInfoData.from(deviceInfo),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    const userId = builder.createString(this.userId);

    const deviceInfo = this.deviceInfo.finish(builder);

    idl.BridgingHandshake.startBridgingHandshake(builder);
    idl.BridgingHandshake.addUserId(builder, userId);

    idl.BridgingHandshake.addDeviceInfo(builder, deviceInfo);

    return idl.BridgingHandshake.endBridgingHandshake(builder);
  }
}

export class BridgingStepUserConfirmation extends BridgingStep {
  code: string;

  constructor(props: NonMethods<BridgingStepUserConfirmation>) {
    super(idl.BridgingStep.UserConfirmation);

    Object.assign(this, props);
  }

  static from(
    userConfirmation: idl.BridgingUserConfirmation,
  ): BridgingStepUserConfirmation {
    return new BridgingStepUserConfirmation({
      code: userConfirmation.code(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    return idl.BridgingUserConfirmation.createBridgingUserConfirmation(
      builder,
      builder.createString(this.code),
    );
  }
}

export class BridgingStepAuthorized extends BridgingStep {
  constructor() {
    super(idl.BridgingStep.Authorized);
  }

  static from(_: idl.BridgingAuthorized): BridgingStepAuthorized {
    return new BridgingStepAuthorized();
  }

  finish(builder: flatbuffers.Builder): number {
    return idl.BridgingAuthorized.createBridgingAuthorized(builder);
  }
}

export class BridgingStepPublicKey extends BridgingStep {
  publicKey: Uint8Array;

  constructor(props: NonMethods<BridgingStepPublicKey>) {
    super(idl.BridgingStep.PublicKey);

    Object.assign(this, props);
  }

  static from(publicKey: idl.BridgingPublicKey): BridgingStepPublicKey {
    return new BridgingStepPublicKey({
      publicKey: publicKey.publicKeyArray(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    return idl.BridgingPublicKey.createBridgingPublicKey(
      builder,
      idl.BridgingPublicKey.createPublicKeyVector(builder, this.publicKey),
    );
  }
}

export class BridgingStepChallenge extends BridgingStep {
  challenge: Uint8Array;
  hash: Uint8Array;

  constructor(props: NonMethods<BridgingStepChallenge>) {
    super(idl.BridgingStep.Challenge);

    Object.assign(this, props);
  }

  static from(challenge: idl.BridgingChallenge): BridgingStepChallenge {
    return new BridgingStepChallenge({
      challenge: challenge.challengeArray(),
      hash: challenge.hashArray(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    return idl.BridgingChallenge.createBridgingChallenge(
      builder,
      idl.BridgingChallenge.createChallengeVector(builder, this.challenge),
      idl.BridgingChallenge.createHashVector(builder, this.hash),
    );
  }
}

export class BridgingStepAnswer extends BridgingStep {
  result: Uint8Array;

  constructor(props: NonMethods<BridgingStepAnswer>) {
    super(idl.BridgingStep.Answer);

    Object.assign(this, props);
  }

  static from(answer: idl.BridgingAnswer): BridgingStepAnswer {
    return new BridgingStepAnswer({
      result: answer.resultArray(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    return idl.BridgingAnswer.createBridgingAnswer(
      builder,
      idl.BridgingAnswer.createResultVector(builder, this.result),
    );
  }
}
