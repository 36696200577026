// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

import { BridgeDeviceInfo } from "@/feature-bridge/idl/bridge_device/bridge-device-info.mjs";

export class BridgingHandshake {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BridgingHandshake {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBridgingHandshake(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingHandshake,
  ): BridgingHandshake {
    return (obj || new BridgingHandshake()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBridgingHandshake(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingHandshake,
  ): BridgingHandshake {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BridgingHandshake()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  userId(): string | null;
  userId(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  userId(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  deviceInfo(obj?: BridgeDeviceInfo): BridgeDeviceInfo | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? (obj || new BridgeDeviceInfo()).__init(
          this.bb!.__indirect(this.bb_pos + offset),
          this.bb!,
        )
      : null;
  }

  static startBridgingHandshake(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addUserId(
    builder: flatbuffers.Builder,
    userIdOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, userIdOffset, 0);
  }

  static addDeviceInfo(
    builder: flatbuffers.Builder,
    deviceInfoOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, deviceInfoOffset, 0);
  }

  static endBridgingHandshake(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // user_id
    builder.requiredField(offset, 6); // device_info
    return offset;
  }
}
