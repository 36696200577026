import * as flatbuffers from "flatbuffers";
import { parse as uuidToBytes } from "uuid";

import { BridgeDeviceInfoData } from "@/feature-bridge/bridge-device.mjs";
import * as idl from "@/feature-bridge/idl/discovery_packet/discovery_packet.mjs";
import globals from "@/util/global-whitelist.mjs";

export class DiscoveryPacketIncoming<Packet extends DiscoveryPacket> {
  peerId: string;
  nonce: number;

  payload: Packet;

  constructor(args: {
    peerId: string;
    nonce: number;

    payload: Packet;
  }) {
    Object.assign(this, args);
  }

  static parse({
    peerId,
    nonce,
    packet,
  }: {
    peerId: string;
    nonce: number;
    packet: Uint8Array;
  }) {
    return new DiscoveryPacketIncoming({
      peerId,
      nonce,
      payload: DiscoveryPacket.parse(packet),
    });
  }
}

export abstract class DiscoveryPacket {
  #type: idl.DiscoveryPacket;

  constructor(type: idl.DiscoveryPacket) {
    this.#type = type;
  }

  static async deriveHash({
    userId,
    senderId,
    receiverId,
  }: {
    userId: string;
    senderId: string;
    receiverId?: string;
  }): Promise<Uint8Array> {
    return new Uint8Array(
      await globals.crypto.subtle.digest(
        { name: "SHA-1" },
        new Uint8Array([
          ...new TextEncoder().encode(userId),
          ...uuidToBytes(senderId),
          ...(receiverId ? uuidToBytes(receiverId) : []),
        ]),
      ),
    );
  }

  static parse(bytes: Uint8Array) {
    const discoveryPacket = idl.DiscoveryMessage.getRootAsDiscoveryMessage(
      new flatbuffers.ByteBuffer(bytes),
    );

    const packetType = discoveryPacket.packetType();

    const packet = idl.unionToDiscoveryPacket(packetType, (...args) => {
      return discoveryPacket.packet(...args);
    });

    if (packet instanceof idl.DiscoveryAdvertisement) {
      return DiscoveryPacketAdvertisement.from(packet);
    } else if (packet instanceof idl.DiscoveryProbe) {
      return DiscoveryPacketProbe.from(packet);
    } else if (packet instanceof idl.DiscoveryInquiry) {
      return DiscoveryPacketInquiry.from(packet);
    } else if (packet instanceof idl.DiscoveryInquiryReply) {
      return DiscoveryPacketInquiryReply.from(packet);
    } else if (packet instanceof idl.DiscoverySignal) {
      return DiscoveryPacketSignal.from(packet);
    } else if (packet instanceof idl.DiscoveryCandidate) {
      return DiscoveryPacketCandidate.from(packet);
    }

    throw new Error(`Unknown packet type: ${packetType}`);
  }

  asBytes(): Uint8Array {
    const builder = new flatbuffers.Builder();

    builder.finish(
      idl.DiscoveryMessage.createDiscoveryMessage(
        builder,
        this.#type,
        this.finish(builder),
      ),
    );

    return builder.asUint8Array();
  }

  abstract finish(builder: flatbuffers.Builder): number;
}

export class DiscoveryPacketAdvertisement extends DiscoveryPacket {
  derivedHash: Uint8Array;

  constructor(props: NonMethods<DiscoveryPacketAdvertisement>) {
    super(idl.DiscoveryPacket.Advertisement);

    Object.assign(this, props);
  }

  static from(
    advertisement: idl.DiscoveryAdvertisement,
  ): DiscoveryPacketAdvertisement {
    return new DiscoveryPacketAdvertisement({
      derivedHash: advertisement.derivedHashArray(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    return idl.DiscoveryAdvertisement.createDiscoveryAdvertisement(
      builder,
      idl.DiscoveryAdvertisement.createDerivedHashVector(
        builder,
        this.derivedHash,
      ),
    );
  }
}

export class DiscoveryPacketProbe extends DiscoveryPacket {
  derivedHash: Uint8Array;

  constructor(props: NonMethods<DiscoveryPacketProbe>) {
    super(idl.DiscoveryPacket.Probe);

    Object.assign(this, props);
  }

  static from(probe: idl.DiscoveryProbe): DiscoveryPacketProbe {
    return new DiscoveryPacketProbe({
      derivedHash: probe.derivedHashArray(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    return idl.DiscoveryProbe.createDiscoveryProbe(
      builder,
      idl.DiscoveryProbe.createDerivedHashVector(builder, this.derivedHash),
    );
  }
}

export class DiscoveryPacketInquiry extends DiscoveryPacket {
  derivedHash: Uint8Array;

  constructor(props: NonMethods<DiscoveryPacketInquiry>) {
    super(idl.DiscoveryPacket.Inquiry);

    Object.assign(this, props);
  }

  static from(inquiry: idl.DiscoveryInquiry): DiscoveryPacketInquiry {
    return new DiscoveryPacketInquiry({
      derivedHash: inquiry.derivedHashArray(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    return idl.DiscoveryInquiry.createDiscoveryInquiry(
      builder,
      idl.DiscoveryInquiry.createDerivedHashVector(builder, this.derivedHash),
    );
  }
}

export class DiscoveryPacketInquiryReply extends DiscoveryPacket {
  derivedHash: Uint8Array;

  deviceInfo: BridgeDeviceInfoData;

  constructor(props: NonMethods<DiscoveryPacketInquiryReply>) {
    super(idl.DiscoveryPacket.InquiryReply);

    Object.assign(this, props);
  }

  static from(
    inquiryReply: idl.DiscoveryInquiryReply,
  ): DiscoveryPacketInquiryReply {
    const deviceInfo = inquiryReply.deviceInfo();

    if (deviceInfo === null) {
      throw new Error("Discovery inquiry reply contains invalid device info");
    }

    return new DiscoveryPacketInquiryReply({
      derivedHash: inquiryReply.derivedHashArray(),

      deviceInfo: BridgeDeviceInfoData.from(deviceInfo),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    const derivedHash = idl.DiscoveryInquiryReply.createDerivedHashVector(
      builder,
      this.derivedHash,
    );

    const deviceInfo = this.deviceInfo.finish(builder);

    idl.DiscoveryInquiryReply.startDiscoveryInquiryReply(builder);
    idl.DiscoveryInquiryReply.addDerivedHash(builder, derivedHash);

    idl.DiscoveryInquiryReply.addDeviceInfo(builder, deviceInfo);

    return idl.DiscoveryInquiryReply.endDiscoveryInquiryReply(builder);
  }
}

export class DiscoveryPacketSignal extends DiscoveryPacket {
  derivedHash: Uint8Array;

  deviceInfo: BridgeDeviceInfoData;

  sdp: string;

  constructor(props: NonMethods<DiscoveryPacketSignal>) {
    super(idl.DiscoveryPacket.Signal);

    Object.assign(this, props);
  }

  static from(signal: idl.DiscoverySignal): DiscoveryPacketSignal {
    const deviceInfo = signal.deviceInfo();

    if (deviceInfo === null) {
      throw new Error("Discovery signal contains invalid device info");
    }

    return new DiscoveryPacketSignal({
      derivedHash: signal.derivedHashArray(),

      deviceInfo: BridgeDeviceInfoData.from(deviceInfo),

      sdp: signal.sdp(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    const derivedHash = idl.DiscoveryInquiryReply.createDerivedHashVector(
      builder,
      this.derivedHash,
    );

    const deviceInfo = this.deviceInfo.finish(builder);

    const sdp = builder.createString(this.sdp);

    idl.DiscoverySignal.startDiscoverySignal(builder);
    idl.DiscoveryInquiryReply.addDerivedHash(builder, derivedHash);

    idl.DiscoverySignal.addDeviceInfo(builder, deviceInfo);

    idl.DiscoverySignal.addSdp(builder, sdp);

    return idl.DiscoverySignal.endDiscoverySignal(builder);
  }
}

export class DiscoveryPacketCandidate extends DiscoveryPacket {
  derivedHash: Uint8Array;

  candidate: string;
  sdpMid: string;
  sdpMLineIndex: number;

  constructor(props: NonMethods<DiscoveryPacketCandidate>) {
    super(idl.DiscoveryPacket.Candidate);

    Object.assign(this, props);
  }

  static from(candidate: idl.DiscoveryCandidate): DiscoveryPacketCandidate {
    return new DiscoveryPacketCandidate({
      derivedHash: candidate.derivedHashArray(),
      candidate: candidate.candidate(),
      sdpMid: candidate.sdpMid(),
      sdpMLineIndex: candidate.sdpMLineIndex(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    return idl.DiscoveryCandidate.createDiscoveryCandidate(
      builder,
      idl.DiscoveryInquiryReply.createDerivedHashVector(
        builder,
        this.derivedHash,
      ),
      builder.createString(this.candidate),
      builder.createString(this.sdpMid),
      this.sdpMLineIndex,
    );
  }
}
