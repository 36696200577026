// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

import { BridgeDeviceInfo } from "@/feature-bridge/idl/bridge_device/bridge-device-info.mjs";

export class BlitzConnectSignal {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BlitzConnectSignal {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBlitzConnectSignal(
    bb: flatbuffers.ByteBuffer,
    obj?: BlitzConnectSignal,
  ): BlitzConnectSignal {
    return (obj || new BlitzConnectSignal()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBlitzConnectSignal(
    bb: flatbuffers.ByteBuffer,
    obj?: BlitzConnectSignal,
  ): BlitzConnectSignal {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BlitzConnectSignal()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  deviceInfo(obj?: BridgeDeviceInfo): BridgeDeviceInfo | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? (obj || new BridgeDeviceInfo()).__init(
          this.bb!.__indirect(this.bb_pos + offset),
          this.bb!,
        )
      : null;
  }

  sdp(): string | null;
  sdp(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  sdp(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  static startBlitzConnectSignal(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addDeviceInfo(
    builder: flatbuffers.Builder,
    deviceInfoOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, deviceInfoOffset, 0);
  }

  static addSdp(builder: flatbuffers.Builder, sdpOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, sdpOffset, 0);
  }

  static endBlitzConnectSignal(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // device_info
    builder.requiredField(offset, 6); // sdp
    return offset;
  }

  static createBlitzConnectSignal(
    builder: flatbuffers.Builder,
    deviceInfoOffset: flatbuffers.Offset,
    sdpOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    BlitzConnectSignal.startBlitzConnectSignal(builder);
    BlitzConnectSignal.addDeviceInfo(builder, deviceInfoOffset);
    BlitzConnectSignal.addSdp(builder, sdpOffset);
    return BlitzConnectSignal.endBlitzConnectSignal(builder);
  }
}
