// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class DiscoveryCandidate {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): DiscoveryCandidate {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsDiscoveryCandidate(
    bb: flatbuffers.ByteBuffer,
    obj?: DiscoveryCandidate,
  ): DiscoveryCandidate {
    return (obj || new DiscoveryCandidate()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsDiscoveryCandidate(
    bb: flatbuffers.ByteBuffer,
    obj?: DiscoveryCandidate,
  ): DiscoveryCandidate {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new DiscoveryCandidate()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  derivedHash(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  derivedHashLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  derivedHashArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  candidate(): string | null;
  candidate(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  candidate(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  sdpMid(): string | null;
  sdpMid(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  sdpMid(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  sdpMLineIndex(): number {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  static startDiscoveryCandidate(builder: flatbuffers.Builder) {
    builder.startObject(4);
  }

  static addDerivedHash(
    builder: flatbuffers.Builder,
    derivedHashOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, derivedHashOffset, 0);
  }

  static createDerivedHashVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startDerivedHashVector(
    builder: flatbuffers.Builder,
    numElems: number,
  ) {
    builder.startVector(1, numElems, 1);
  }

  static addCandidate(
    builder: flatbuffers.Builder,
    candidateOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, candidateOffset, 0);
  }

  static addSdpMid(
    builder: flatbuffers.Builder,
    sdpMidOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(2, sdpMidOffset, 0);
  }

  static addSdpMLineIndex(builder: flatbuffers.Builder, sdpMLineIndex: number) {
    builder.addFieldInt32(3, sdpMLineIndex, 0);
  }

  static endDiscoveryCandidate(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // derived_hash
    builder.requiredField(offset, 6); // candidate
    builder.requiredField(offset, 8); // sdp_mid
    return offset;
  }

  static createDiscoveryCandidate(
    builder: flatbuffers.Builder,
    derivedHashOffset: flatbuffers.Offset,
    candidateOffset: flatbuffers.Offset,
    sdpMidOffset: flatbuffers.Offset,
    sdpMLineIndex: number,
  ): flatbuffers.Offset {
    DiscoveryCandidate.startDiscoveryCandidate(builder);
    DiscoveryCandidate.addDerivedHash(builder, derivedHashOffset);
    DiscoveryCandidate.addCandidate(builder, candidateOffset);
    DiscoveryCandidate.addSdpMid(builder, sdpMidOffset);
    DiscoveryCandidate.addSdpMLineIndex(builder, sdpMLineIndex);
    return DiscoveryCandidate.endDiscoveryCandidate(builder);
  }
}
