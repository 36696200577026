import * as bridgeDeviceIdl from "@/feature-bridge/idl/bridge_device/bridge_device.mjs";

export type BridgeDevicePlatform =
  | "android"
  | "ios"
  | "windows"
  | "macos"
  | "linux"
  | "web"
  | "overlay"
  | "unknown";

export const BRIDGE_DEVICE_PLATFORM_MAPPING: Record<
  bridgeDeviceIdl.BridgeDevicePlatform,
  BridgeDevicePlatform
> = {
  [bridgeDeviceIdl.BridgeDevicePlatform.Android]: "android",
  [bridgeDeviceIdl.BridgeDevicePlatform.iOS]: "ios",
  [bridgeDeviceIdl.BridgeDevicePlatform.MacOS]: "macos",
  [bridgeDeviceIdl.BridgeDevicePlatform.Windows]: "windows",
  [bridgeDeviceIdl.BridgeDevicePlatform.Linux]: "linux",
  [bridgeDeviceIdl.BridgeDevicePlatform.Web]: "web",
  [bridgeDeviceIdl.BridgeDevicePlatform.Overlay]: "overlay",
  [bridgeDeviceIdl.BridgeDevicePlatform.Unknown]: "unknown",
};

export const BRIDGE_DEVICE_REVERSE_PLATFORM_MAPPING: Record<
  BridgeDevicePlatform,
  bridgeDeviceIdl.BridgeDevicePlatform
> = Object.fromEntries(
  Object.entries(BRIDGE_DEVICE_PLATFORM_MAPPING).map((a) => a.reverse()),
);

export type BridgeDeviceInfo = {
  deviceId: string;
  name: string;
  platform: BridgeDevicePlatform;
  operatingSystem: string;
};

export interface BridgeAuthorizedDevice {
  publicKey: number[];

  device: BridgeDeviceInfo;
}

interface BridgeState {
  device: BridgeDeviceInfo;

  authorizedDevices: {
    [uniqueId: string]: BridgeAuthorizedDevice;
  };

  connections: Array<string>;
}

declare module "@/__main__/app-state.mjs" {
  interface FeatureState {
    bridge: BridgeState;
  }
}
