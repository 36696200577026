// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import { BridgingAnswer } from "@/feature-bridge/idl/bridging/bridging-answer.mjs";
import { BridgingAuthorized } from "@/feature-bridge/idl/bridging/bridging-authorized.mjs";
import { BridgingChallenge } from "@/feature-bridge/idl/bridging/bridging-challenge.mjs";
import { BridgingHandshake } from "@/feature-bridge/idl/bridging/bridging-handshake.mjs";
import { BridgingPublicKey } from "@/feature-bridge/idl/bridging/bridging-public-key.mjs";
import { BridgingUserConfirmation } from "@/feature-bridge/idl/bridging/bridging-user-confirmation.mjs";

export enum BridgingStep {
  NONE = 0,
  Handshake = 1,
  UserConfirmation = 2,
  Authorized = 3,
  PublicKey = 4,
  Challenge = 5,
  Answer = 6,
}

export function unionToBridgingStep(
  type: BridgingStep,
  accessor: (
    obj:
      | BridgingAnswer
      | BridgingAuthorized
      | BridgingChallenge
      | BridgingHandshake
      | BridgingPublicKey
      | BridgingUserConfirmation,
  ) =>
    | BridgingAnswer
    | BridgingAuthorized
    | BridgingChallenge
    | BridgingHandshake
    | BridgingPublicKey
    | BridgingUserConfirmation
    | null,
):
  | BridgingAnswer
  | BridgingAuthorized
  | BridgingChallenge
  | BridgingHandshake
  | BridgingPublicKey
  | BridgingUserConfirmation
  | null {
  switch (BridgingStep[type]) {
    case "NONE":
      return null;
    case "Handshake":
      return accessor(new BridgingHandshake())! as BridgingHandshake;
    case "UserConfirmation":
      return accessor(
        new BridgingUserConfirmation(),
      )! as BridgingUserConfirmation;
    case "Authorized":
      return accessor(new BridgingAuthorized())! as BridgingAuthorized;
    case "PublicKey":
      return accessor(new BridgingPublicKey())! as BridgingPublicKey;
    case "Challenge":
      return accessor(new BridgingChallenge())! as BridgingChallenge;
    case "Answer":
      return accessor(new BridgingAnswer())! as BridgingAnswer;
    default:
      return null;
  }
}

export function unionListToBridgingStep(
  type: BridgingStep,
  accessor: (
    index: number,
    obj:
      | BridgingAnswer
      | BridgingAuthorized
      | BridgingChallenge
      | BridgingHandshake
      | BridgingPublicKey
      | BridgingUserConfirmation,
  ) =>
    | BridgingAnswer
    | BridgingAuthorized
    | BridgingChallenge
    | BridgingHandshake
    | BridgingPublicKey
    | BridgingUserConfirmation
    | null,
  index: number,
):
  | BridgingAnswer
  | BridgingAuthorized
  | BridgingChallenge
  | BridgingHandshake
  | BridgingPublicKey
  | BridgingUserConfirmation
  | null {
  switch (BridgingStep[type]) {
    case "NONE":
      return null;
    case "Handshake":
      return accessor(index, new BridgingHandshake())! as BridgingHandshake;
    case "UserConfirmation":
      return accessor(
        index,
        new BridgingUserConfirmation(),
      )! as BridgingUserConfirmation;
    case "Authorized":
      return accessor(index, new BridgingAuthorized())! as BridgingAuthorized;
    case "PublicKey":
      return accessor(index, new BridgingPublicKey())! as BridgingPublicKey;
    case "Challenge":
      return accessor(index, new BridgingChallenge())! as BridgingChallenge;
    case "Answer":
      return accessor(index, new BridgingAnswer())! as BridgingAnswer;
    default:
      return null;
  }
}
