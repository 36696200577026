import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import { BridgeDeviceInfoData } from "@/feature-bridge/bridge-device.mjs";
import type { WebRTCConnection } from "@/feature-bridge/connection.mjs";
import { WebRTCConnectionBuilder } from "@/feature-bridge/connection.mjs";
import {
  DiscoveryPacket,
  DiscoveryPacketAdvertisement,
  DiscoveryPacketCandidate,
  DiscoveryPacketIncoming,
  DiscoveryPacketInquiry,
  DiscoveryPacketInquiryReply,
  DiscoveryPacketProbe,
  DiscoveryPacketSignal,
} from "@/feature-bridge/discovery-packet.mjs";
import { devDebug, devWarn } from "@/util/dev.mjs";
import { StrictEventEmitter } from "@/util/strict-event-emitter.mjs";

type DiscoveryEvents = {
  connection(connection: WebRTCConnection): void;
};

export default class Discovery extends StrictEventEmitter<DiscoveryEvents> {
  #peerId: string;

  #pendingConnections = new Map<string, WebRTCConnection>();

  async startListening() {
    try {
      const { peerId } = (await blitzMessage(
        EVENTS.BRIDGE_DISCOVERY_OPEN,
      )) as unknown as Record<string, string>;

      this.#peerId = peerId;

      devDebug("[discovery] Discovery socket has been opened");
    } catch (e) {
      devDebug("[discovery] Failed to open discovery socket", e);

      return;
    }

    await this.sendAdvertismentPacket();
  }

  async stopListening() {
    try {
      await blitzMessage(EVENTS.BRIDGE_DISCOVERY_CLOSE);

      devDebug("[discovery] Discovery socket has been closed");
    } catch (e) {
      devDebug("[discovery] Failed to close discovery socket", e);
    }
  }

  async sendAdvertismentPacket() {
    const userId = readState.user?.id;

    if (!userId) return;

    devDebug(`[discovery] Broadcasting advertisement as ${this.#peerId}`);

    await this.sendPacket(
      new DiscoveryPacketAdvertisement({
        derivedHash: await DiscoveryPacket.deriveHash({
          userId,
          senderId: this.#peerId,
        }),
      }),
    );
  }

  async sendPacket(
    packet: DiscoveryPacket,
    { replyTo }: { replyTo?: string } = {},
  ) {
    await blitzMessage(EVENTS.BRIDGE_DISCOVERY_MESSAGE, {
      payload: packet.asBytes(),

      replyTo,
    });
  }

  async onReceiveMessage(msg: BridgeDiscoveryMessageIncoming) {
    const userId = readState.user?.id;

    if (!userId) return;

    const packet = DiscoveryPacketIncoming.parse({
      peerId: msg.peerId,
      nonce: msg.nonce,
      packet: msg.payload,
    });

    const payload = packet.payload;

    if (payload instanceof DiscoveryPacketAdvertisement) {
      devDebug(`[discovery] Detected advertisement by another peer`, {
        peerId: packet.peerId,
      });
    } else if (payload instanceof DiscoveryPacketProbe) {
      // Discovery can be done by asking for a specific device, or by asking for all devices. This is
      // detected by the use of `receiverId` in the hash.

      let derivedHash = await DiscoveryPacket.deriveHash({
        userId,
        senderId: packet.peerId,
        receiverId: readState.bridge.device.deviceId,
      });

      if (derivedHash.some((v, i) => v !== payload.derivedHash[i])) {
        derivedHash = await DiscoveryPacket.deriveHash({
          userId,
          senderId: packet.peerId,
        });

        if (derivedHash.some((v, i) => v !== payload.derivedHash[i])) {
          devDebug(`[discovery] Detected inquiry an unknown user`, {
            peerId: packet.peerId,
          });

          return;
        }

        devDebug(
          `[discovery] Received discovery for the current user, replying`,
          { peerId: packet.peerId },
        );
      } else {
        devDebug(`[discovery] Received discovery for the current device`, {
          peerId: packet.peerId,
        });
      }

      await this.sendPacket(
        new DiscoveryPacketAdvertisement({
          derivedHash: await DiscoveryPacket.deriveHash({
            userId,
            senderId: this.#peerId,
          }),
        }),
        { replyTo: packet.peerId },
      );
    } else if (payload instanceof DiscoveryPacketInquiry) {
      const derivedHash = await DiscoveryPacket.deriveHash({
        userId,
        senderId: packet.peerId,
        receiverId: this.#peerId,
      });

      // If the hashes match, then we know that the sender is the same user and can reply
      if (derivedHash.some((v, i) => v !== payload.derivedHash[i])) {
        devDebug(`[discovery] Detected inquiry for an unknown user`, {
          peerId: packet.peerId,
        });

        return;
      }

      devDebug(`[discovery] Received inquiry for the current user, replying`, {
        peerId: packet.peerId,
      });

      await this.sendPacket(
        new DiscoveryPacketInquiryReply({
          derivedHash: await DiscoveryPacket.deriveHash({
            userId,
            senderId: this.#peerId,
            receiverId: packet.peerId,
          }),
          deviceInfo: new BridgeDeviceInfoData({
            deviceId: readState.bridge.device.deviceId,
            name: readState.bridge.device.name,
            platform: readState.bridge.device.platform,
            operatingSystem: readState.bridge.device.operatingSystem,
          }),
        }),
        { replyTo: packet.peerId },
      );
    } else if (payload instanceof DiscoveryPacketInquiryReply) {
      devWarn(`[discovery] Received erroneous inquiry reply`, {
        peerId: packet.peerId,
      });
    } else if (payload instanceof DiscoveryPacketSignal) {
      const derivedHash = await DiscoveryPacket.deriveHash({
        userId,
        senderId: packet.peerId,
        receiverId: this.#peerId,
      });

      // If the hashes match, then we know that the sender is the same user and can reply
      if (derivedHash.some((v, i) => v !== payload.derivedHash[i])) {
        devDebug(`[discovery] Received invalid signal`, {
          peerId: packet.peerId,
        });

        return;
      }

      const otherDeviceId = payload.deviceInfo.deviceId;

      devDebug(
        `[discovery] Received signal, attempting to establish a connection`,
        {
          peerId: packet.peerId,
          deviceId: otherDeviceId,
        },
      );

      const connectionBuilder = WebRTCConnectionBuilder.create();
      connectionBuilder.deviceId = otherDeviceId;
      const connection = connectionBuilder.finalize();

      this.#pendingConnections.set(packet.peerId, connection);

      const answerSdp = await connection.createAnswer(payload.sdp);

      const trickleCandidates = async (candidate: RTCIceCandidate) => {
        devDebug(`[discovery] Found candidate, notifying peer`, {
          peerId: packet.peerId,
          deviceId: otherDeviceId,
        });

        await this.sendPacket(
          new DiscoveryPacketCandidate({
            derivedHash: await DiscoveryPacket.deriveHash({
              userId,
              senderId: this.#peerId,
              receiverId: packet.peerId,
            }),
            candidate: candidate.candidate,
            sdpMLineIndex: candidate.sdpMLineIndex,
            sdpMid: candidate.sdpMid,
          }),
          { replyTo: packet.peerId },
        );
      };

      connection.on("candidate", trickleCandidates);

      connection.on("ready", () => {
        devDebug("[discovery] Connection established", {
          peerId: packet.peerId,
          deviceId: otherDeviceId,
        });

        this.#pendingConnections.delete(packet.peerId);

        connection.off("candidate", trickleCandidates);

        this.emit("connection", connection);
      });

      await this.sendPacket(
        new DiscoveryPacketSignal({
          derivedHash: await DiscoveryPacket.deriveHash({
            userId,
            senderId: this.#peerId,
            receiverId: packet.peerId,
          }),

          deviceInfo: new BridgeDeviceInfoData({
            deviceId: readState.bridge.device.deviceId,
            name: readState.bridge.device.name,
            platform: readState.bridge.device.platform,
            operatingSystem: readState.bridge.device.operatingSystem,
          }),

          sdp: answerSdp,
        }),
        { replyTo: packet.peerId },
      );
    } else if (payload instanceof DiscoveryPacketCandidate) {
      const derivedHash = await DiscoveryPacket.deriveHash({
        userId,
        senderId: packet.peerId,
        receiverId: this.#peerId,
      });

      // If the hashes match, then we know that the sender is the same user and can reply
      if (derivedHash.some((v, i) => v !== payload.derivedHash[i])) {
        devWarn(`[discovery] Received invalid candidate`, {
          peerId: packet.peerId,
        });

        return;
      }

      const connection = this.#pendingConnections.get(packet.peerId);

      if (!connection) {
        devDebug(
          `[discovery] Received candidate, but no pending connection exists`,
          {
            peerId: packet.peerId,
          },
        );

        return;
      }

      devDebug(`[discovery] Received candidate, adding to pending connection`, {
        peerId: packet.peerId,
        deviceId: connection.device.deviceId,
      });

      await connection.onReceivedCandidate({
        candidate: payload.candidate,
        sdpMid: payload.sdpMid,
        sdpMLineIndex: payload.sdpMLineIndex,
      });
    }
  }
}

export interface BridgeDiscoveryMessageIncoming {
  peerId: string;
  nonce: number;

  payload: Uint8Array;
}
