// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

import {
  RpcBody,
  unionListToRpcBody,
  unionToRpcBody,
} from "@/feature-bridge/idl/rpc_message/rpc-body.mjs";

export class RpcMessage {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): RpcMessage {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsRpcMessage(
    bb: flatbuffers.ByteBuffer,
    obj?: RpcMessage,
  ): RpcMessage {
    return (obj || new RpcMessage()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsRpcMessage(
    bb: flatbuffers.ByteBuffer,
    obj?: RpcMessage,
  ): RpcMessage {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new RpcMessage()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  bodyType(): RpcBody {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readUint8(this.bb_pos + offset) : RpcBody.NONE;
  }

  body<T extends flatbuffers.Table>(obj: any): any | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
  }

  static startRpcMessage(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addBodyType(builder: flatbuffers.Builder, bodyType: RpcBody) {
    builder.addFieldInt8(0, bodyType, RpcBody.NONE);
  }

  static addBody(builder: flatbuffers.Builder, bodyOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, bodyOffset, 0);
  }

  static endRpcMessage(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static finishRpcMessageBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset);
  }

  static finishSizePrefixedRpcMessageBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset, undefined, true);
  }

  static createRpcMessage(
    builder: flatbuffers.Builder,
    bodyType: RpcBody,
    bodyOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    RpcMessage.startRpcMessage(builder);
    RpcMessage.addBodyType(builder, bodyType);
    RpcMessage.addBody(builder, bodyOffset);
    return RpcMessage.endRpcMessage(builder);
  }
}
