import type { BridgeMessageChannelArgs } from "@/feature-bridge/bridge-message-channel.mjs";
import { BridgeMessageChannel } from "@/feature-bridge/bridge-message-channel.mjs";
import type { BridgeRpcChannelArgs } from "@/feature-bridge/bridge-rpc-channel.mjs";
import { BridgeRpcChannel } from "@/feature-bridge/bridge-rpc-channel.mjs";
import type { IConnection } from "@/feature-bridge/connection.mjs";
import { devWarn } from "@/util/dev.mjs";
import { StrictEventEmitter } from "@/util/strict-event-emitter.mjs";

type BridgeEvents = {
  ready();

  connected(args: { connection: IConnection });
  disconnected(args: { connection: IConnection });
  authorized(args: { connection: IConnection });

  unauthorizedMessage(args: {
    connection: IConnection;
    channel: string;
    data: Uint8Array;
  });

  authorizedMessage(args: {
    connection: IConnection;
    channel: string;
    data: Uint8Array;
  });
};

export class Bridge extends StrictEventEmitter<BridgeEvents> {
  #connections = new Map<string, IConnection>();
  #authorizedConnections = new Set<string>();

  get connectedDevices() {
    return Array.from(this.#connections.keys());
  }

  *getConnectedUnauthorizedDevices(): Generator<string, undefined, undefined> {
    for (const connection of this.#connections.values()) {
      if (!this.#authorizedConnections.has(connection.device.deviceId)) {
        yield connection.device.deviceId;
      }
    }
  }

  *getConnectedAuthorizedDevices(): Generator<string, undefined, undefined> {
    for (const connection of this.#connections.values()) {
      if (this.#authorizedConnections.has(connection.device.deviceId)) {
        yield connection.device.deviceId;
      }
    }
  }

  addConnection(connection: IConnection, { authorized = false } = {}) {
    // Close any open connections to the device
    this.#connections.get(connection.device.deviceId)?.close();

    this.#connections.set(connection.device.deviceId, connection);

    connection.on("data", (msg) => {
      try {
        if (!this.#authorizedConnections.has(connection.device.deviceId)) {
          this.emit("unauthorizedMessage", {
            connection,
            channel: msg.channel,
            data: msg.data,
          });
        } else {
          this.emit("authorizedMessage", {
            connection,
            channel: msg.channel,
            data: msg.data,
          });
        }
      } catch (err) {
        devWarn(`Error handling message`, { msg, err });
      }
    });

    connection.on("close", () => {
      this.closeConnection(connection.device.deviceId);
    });

    if (authorized) {
      this.#authorizedConnections.add(connection.device.deviceId);
    }

    this.emit("connected", { connection });
  }

  setDeviceAuthorized(deviceId: string) {
    const connection = this.#connections.get(deviceId);

    if (!connection) {
      return;
    }

    this.#authorizedConnections.add(connection.device.deviceId);

    this.emit("authorized", { connection });
  }

  doesRequireAuthentication(deviceId: string) {
    return this.#connections.get(deviceId)?.requiresAuthentication ?? false;
  }

  closeConnection(deviceId: string) {
    const connection = this.#connections.get(deviceId);

    if (!connection) {
      return;
    }

    this.#connections.delete(deviceId);
    this.#authorizedConnections.delete(deviceId);

    connection.close();

    this.emit("disconnected", { connection });
  }

  openMessageChannel(args: BridgeMessageChannelArgs) {
    return new BridgeMessageChannel(this, args);
  }

  openRpcChannel<Params, Result>(args: BridgeRpcChannelArgs<Params, Result>) {
    return new BridgeRpcChannel(this, args);
  }
}

export default new Bridge();
