// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

import {
  BlitzConnectPacket,
  unionListToBlitzConnectPacket,
  unionToBlitzConnectPacket,
} from "@/feature-bridge/idl/blitzconnect_message/blitz-connect-packet.mjs";

export class BlitzConnectMessage {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BlitzConnectMessage {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBlitzConnectMessage(
    bb: flatbuffers.ByteBuffer,
    obj?: BlitzConnectMessage,
  ): BlitzConnectMessage {
    return (obj || new BlitzConnectMessage()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBlitzConnectMessage(
    bb: flatbuffers.ByteBuffer,
    obj?: BlitzConnectMessage,
  ): BlitzConnectMessage {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BlitzConnectMessage()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  bodyType(): BlitzConnectPacket {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb_pos + offset)
      : BlitzConnectPacket.NONE;
  }

  body<T extends flatbuffers.Table>(obj: any): any | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
  }

  static startBlitzConnectMessage(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addBodyType(
    builder: flatbuffers.Builder,
    bodyType: BlitzConnectPacket,
  ) {
    builder.addFieldInt8(0, bodyType, BlitzConnectPacket.NONE);
  }

  static addBody(builder: flatbuffers.Builder, bodyOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, bodyOffset, 0);
  }

  static endBlitzConnectMessage(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 6); // body
    return offset;
  }

  static createBlitzConnectMessage(
    builder: flatbuffers.Builder,
    bodyType: BlitzConnectPacket,
    bodyOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    BlitzConnectMessage.startBlitzConnectMessage(builder);
    BlitzConnectMessage.addBodyType(builder, bodyType);
    BlitzConnectMessage.addBody(builder, bodyOffset);
    return BlitzConnectMessage.endBlitzConnectMessage(builder);
  }
}
