// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class WebRtcCandidate {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): WebRtcCandidate {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsWebRtcCandidate(
    bb: flatbuffers.ByteBuffer,
    obj?: WebRtcCandidate,
  ): WebRtcCandidate {
    return (obj || new WebRtcCandidate()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsWebRtcCandidate(
    bb: flatbuffers.ByteBuffer,
    obj?: WebRtcCandidate,
  ): WebRtcCandidate {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new WebRtcCandidate()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  candidate(): string | null;
  candidate(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  candidate(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  sdpMid(): string | null;
  sdpMid(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  sdpMid(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  sdpMLineIndex(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  static startWebRtcCandidate(builder: flatbuffers.Builder) {
    builder.startObject(3);
  }

  static addCandidate(
    builder: flatbuffers.Builder,
    candidateOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, candidateOffset, 0);
  }

  static addSdpMid(
    builder: flatbuffers.Builder,
    sdpMidOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, sdpMidOffset, 0);
  }

  static addSdpMLineIndex(builder: flatbuffers.Builder, sdpMLineIndex: number) {
    builder.addFieldInt32(2, sdpMLineIndex, 0);
  }

  static endWebRtcCandidate(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // candidate
    builder.requiredField(offset, 6); // sdp_mid
    return offset;
  }

  static finishWebRtcCandidateBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset);
  }

  static finishSizePrefixedWebRtcCandidateBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset, undefined, true);
  }

  static createWebRtcCandidate(
    builder: flatbuffers.Builder,
    candidateOffset: flatbuffers.Offset,
    sdpMidOffset: flatbuffers.Offset,
    sdpMLineIndex: number,
  ): flatbuffers.Offset {
    WebRtcCandidate.startWebRtcCandidate(builder);
    WebRtcCandidate.addCandidate(builder, candidateOffset);
    WebRtcCandidate.addSdpMid(builder, sdpMidOffset);
    WebRtcCandidate.addSdpMLineIndex(builder, sdpMLineIndex);
    return WebRtcCandidate.endWebRtcCandidate(builder);
  }
}
