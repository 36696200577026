// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import { BlitzConnectCandidate } from "@/feature-bridge/idl/blitzconnect_message/blitz-connect-candidate.mjs";
import { BlitzConnectSignal } from "@/feature-bridge/idl/blitzconnect_message/blitz-connect-signal.mjs";

export enum BlitzConnectPacket {
  NONE = 0,
  Signal = 1,
  Candidate = 2,
}

export function unionToBlitzConnectPacket(
  type: BlitzConnectPacket,
  accessor: (
    obj: BlitzConnectCandidate | BlitzConnectSignal,
  ) => BlitzConnectCandidate | BlitzConnectSignal | null,
): BlitzConnectCandidate | BlitzConnectSignal | null {
  switch (BlitzConnectPacket[type]) {
    case "NONE":
      return null;
    case "Signal":
      return accessor(new BlitzConnectSignal())! as BlitzConnectSignal;
    case "Candidate":
      return accessor(new BlitzConnectCandidate())! as BlitzConnectCandidate;
    default:
      return null;
  }
}

export function unionListToBlitzConnectPacket(
  type: BlitzConnectPacket,
  accessor: (
    index: number,
    obj: BlitzConnectCandidate | BlitzConnectSignal,
  ) => BlitzConnectCandidate | BlitzConnectSignal | null,
  index: number,
): BlitzConnectCandidate | BlitzConnectSignal | null {
  switch (BlitzConnectPacket[type]) {
    case "NONE":
      return null;
    case "Signal":
      return accessor(index, new BlitzConnectSignal())! as BlitzConnectSignal;
    case "Candidate":
      return accessor(
        index,
        new BlitzConnectCandidate(),
      )! as BlitzConnectCandidate;
    default:
      return null;
  }
}
