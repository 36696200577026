import * as flatbuffers from "flatbuffers";
import * as flexbuffers from "flatbuffers/ts/flexbuffers";

import bridge from "@/feature-bridge/bridge.mjs";
import * as idl from "@/feature-bridge/channel-lol-lcu-request-idl.mjs";
import { LcuRequestMethod } from "@/feature-bridge/channel-lol-lcu-request-idl.mjs";
import lolClient from "@/game-lol/utils/lol-client.mjs";
import { devDebug } from "@/util/dev.mjs";

bridge.openRpcChannel<
  idl.LcuRequest,
  object | { statusCode: number; message: string }
>({
  name: "game_lol/LCU/rpc",

  params: {
    fromBytes: (bytes) =>
      idl.LcuRequest.getRootAsLcuRequest(new flatbuffers.ByteBuffer(bytes)),
  },

  result: {
    toBytes: (data) => {
      devDebug(`[channel-lol] LCU response`, data);
      return flexbuffers.encode(data);
    },
  },

  handler: async (_, request) => {
    const body = request.bodyArray();
    const object = flexbuffers.toObject(
      body.buffer.slice(body.byteOffset, body.byteOffset + body.byteLength),
    );

    devDebug(
      `[channel-lol] LCU request ${
        LcuRequestMethod[request.method()]
      } ${request.uri()}`,
      object,
    );

    if (!lolClient.isConnected) {
      return {
        statusCode: 521,
        message: "Web Server Is Down: Not connected to LCU",
      };
    }

    try {
      return await lolClient.request(
        LcuRequestMethod[request.method()],
        request.uri(),
        object,
      );
    } catch (e) {
      if (
        e instanceof Error &&
        "errorCode" in e &&
        e.errorCode === "RPC_ERROR" &&
        "httpStatus" in e
      ) {
        let message = e.message;

        // Error response for METHOD URL: ERROR_MESSAGE
        if (message.startsWith("Error response for")) {
          message = message.split(":", 2)[1].trim();
        }

        return {
          statusCode: e.httpStatus,
          message,
        };
      }

      throw e;
    }
  },
});
