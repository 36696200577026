import React, { useEffect, useRef, useState } from "react";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import { addConnection } from "@/feature-bridge/actions.mjs";
import BlitzConnect from "@/feature-bridge/blitzconnect.mjs";
import FullModal from "@/shared/FullModal.jsx";
import { devError } from "@/util/dev.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-6);

  width: calc(var(--sp-1) * 103.5);
  max-width: 100%;
  max-height: calc(100vh - var(--sp-20));
  padding: var(--sp-6);

  background-color: var(--shade7);
  border-radius: var(--br-lg);

  overflow: hidden;
  overflow-y: auto;

  @media (max-width: 900px) {
    gap: var(--sp-4);

    padding: var(--sp-4);
  }

  @media (max-height: 700px) {
    max-height: 520px;
  }

  @media (max-height: 500px) {
    max-height: 370px;
    padding: var(--sp-4);
  }

  & > header {
    margin: var(--sp-4) 0;
  }

  & > img {
    border-radius: var(--br);

    image-rendering: pixelated;
  }
`;

export default function BlitzConnectPopup() {
  const {
    volatile: { blitzConnectPopupVisible },
  } = useSnapshot(readState);

  const modalRef = useRef<HTMLDialogElement>(null);

  const blitzConnectRef = useRef<BlitzConnect | null>(null);
  const [img, setImage] = useState<string | null>(null);

  const closeSignupModal = () => {
    setVolatileKV("blitzConnectPopupVisible", false);

    modalRef.current.close?.();
  };

  useEffect(() => {
    let isMounted = true;

    const abortController = new AbortController();

    queueMicrotask(async () => {
      setImage(null);

      if (isMounted) {
        if (!blitzConnectPopupVisible) {
          return modalRef.current?.close?.();
        }

        if (!modalRef.current.open) {
          modalRef.current.showModal?.();

          const blitzConnect = (blitzConnectRef.current = new BlitzConnect());

          await blitzConnect.connect();

          const qrCode = await blitzConnect.generateQRCode();

          if (abortController.signal.aborted) return;

          setImage(qrCode);

          blitzConnect.on("close", () => {
            if (abortController.signal.aborted) return;

            closeSignupModal();
          });

          blitzConnect.on("connection", (conn) => {
            if (abortController.signal.aborted) return;

            closeSignupModal();

            try {
              addConnection(conn);
            } catch (err) {
              devError("[bridge] Failed to add connection:", err);
            }
          });
        }
      }
    });

    return () => {
      isMounted = false;

      abortController.abort();

      blitzConnectRef.current?.close();

      blitzConnectRef.current = null;
    };
  }, [blitzConnectPopupVisible]);

  return (
    <FullModal ref={modalRef} onModalClose={closeSignupModal}>
      {blitzConnectPopupVisible && (
        <Container>{img && <img src={img} />}</Container>
      )}
    </FullModal>
  );
}
