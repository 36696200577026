import * as flatbuffers from "flatbuffers";

import { BridgeDeviceInfoData } from "@/feature-bridge/bridge-device.mjs";
import * as idl from "@/feature-bridge/idl/blitzconnect_message/blitzconnect_message.mjs";

export abstract class BlitzConnectMessage {
  #type: idl.BlitzConnectPacket;

  constructor(type: idl.BlitzConnectPacket) {
    this.#type = type;
  }

  static parse(bytes: Uint8Array) {
    const message = idl.BlitzConnectMessage.getRootAsBlitzConnectMessage(
      new flatbuffers.ByteBuffer(bytes),
    );

    const bodyType = message.bodyType();

    const body = idl.unionToBlitzConnectPacket(bodyType, (...args) => {
      return message.body(...args);
    });

    if (body instanceof idl.BlitzConnectSignal) {
      return BlitzConnectMessageSignal.from(body);
    } else if (body instanceof idl.BlitzConnectCandidate) {
      return BlitzConnectMessageCandidate.from(body);
    }

    throw new Error(`Unknown message type: ${body}`);
  }

  asBytes(): Uint8Array {
    const builder = new flatbuffers.Builder();

    builder.finish(
      idl.BlitzConnectMessage.createBlitzConnectMessage(
        builder,
        this.#type,
        this.finish(builder),
      ),
    );

    return builder.asUint8Array();
  }

  abstract finish(builder: flatbuffers.Builder): number;
}

export class BlitzConnectMessageSignal extends BlitzConnectMessage {
  deviceInfo: BridgeDeviceInfoData;

  sdp: string;

  constructor(props: NonMethods<BlitzConnectMessageSignal>) {
    super(idl.BlitzConnectPacket.Signal);

    Object.assign(this, props);
  }

  static from(signal: idl.BlitzConnectSignal): BlitzConnectMessageSignal {
    const deviceInfo = signal.deviceInfo();

    if (deviceInfo === null) {
      throw new Error("Signal contains invalid device info");
    }

    return new BlitzConnectMessageSignal({
      deviceInfo: BridgeDeviceInfoData.from(deviceInfo),
      sdp: signal.sdp(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    const sdp = builder.createString(this.sdp);

    return idl.BlitzConnectSignal.createBlitzConnectSignal(
      builder,
      this.deviceInfo.finish(builder),
      sdp,
    );
  }
}

export class BlitzConnectMessageCandidate extends BlitzConnectMessage {
  candidate: string;
  sdpMid: string;
  sdpMLineIndex: number;

  constructor(props: NonMethods<BlitzConnectMessageCandidate>) {
    super(idl.BlitzConnectPacket.Candidate);

    Object.assign(this, props);
  }

  static from(
    candidate: idl.BlitzConnectCandidate,
  ): BlitzConnectMessageCandidate {
    return new BlitzConnectMessageCandidate({
      candidate: candidate.candidate(),
      sdpMid: candidate.sdpMid(),
      sdpMLineIndex: candidate.sdpMLineIndex(),
    });
  }

  finish(builder: flatbuffers.Builder): number {
    return idl.BlitzConnectCandidate.createBlitzConnectCandidate(
      builder,
      builder.createString(this.candidate),
      builder.createString(this.sdpMid),
      this.sdpMLineIndex,
    );
  }
}
