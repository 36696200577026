import { readState } from "@/__main__/app-state.mjs";
import { removeSnackbarMessage, showSnackbar } from "@/app/actions.mjs";
import {
  addAuthorizedDevice,
  removeAuthorizedDevice,
  updateAuthorizedDevice,
} from "@/feature-bridge/actions.mjs";
import bridge from "@/feature-bridge/bridge.mjs";
import {
  BridgingStep,
  BridgingStepAnswer,
  BridgingStepAuthorized,
  BridgingStepChallenge,
  BridgingStepHandshake,
  BridgingStepPublicKey,
  BridgingStepUserConfirmation,
} from "@/feature-bridge/bridging-step.mjs";
import type { IConnection } from "@/feature-bridge/connection.mjs";
import type { BridgeDeviceInfo } from "@/feature-bridge/constants.mjs";
import BlitzLogoIcon from "@/inline-assets/blitz-logo.svg";
import { devLog, devWarn } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import adjectives from "@/vendor/adjectives.json";
import nouns from "@/vendor/nouns.json";

const bridgingMessageChannel = bridge.openMessageChannel({
  name: "bridging",
  authorized: false,
});

type AuthenticationState =
  | { step: "handshake" }
  | { step: "awaiting_user_confirmation"; device: BridgeDeviceInfo }
  | { step: "waiting_for_public_key"; device: BridgeDeviceInfo }
  | {
      step: "awaiting_answer";
      device: BridgeDeviceInfo;
      challenge: Uint8Array;
    };

const authStates = new Map<string, AuthenticationState>();

bridge.on("disconnected", ({ connection }) => {
  authStates.delete(connection.device.deviceId);
});

bridgingMessageChannel.on("message", async ({ connection, data }) => {
  const loggedInUserId = readState.user?.id;

  if (!loggedInUserId) {
    devWarn(
      "[bridge] Cannot complete bridging as no user is currently logged in",
    );

    return;
  }

  if (!authStates.has(connection.device.deviceId)) {
    authStates.set(connection.device.deviceId, {
      step: "handshake",
    });

    connection.on("close", () => {
      authStates.delete(connection.device.deviceId);

      removeSnackbars(connection.device.deviceId);
    });
  }

  const state = authStates.get(connection.device.deviceId);

  try {
    const step = BridgingStep.parse(data);

    if (step instanceof BridgingStepHandshake) {
      if (step.deviceInfo.deviceId !== connection.device.deviceId) {
        throw "Device ID mismatch";
      }

      const userId = step.userId;

      if (loggedInUserId !== userId) {
        throw "[bridge] User ID mismatch";
      }

      const device: BridgeDeviceInfo = {
        deviceId: step.deviceInfo.deviceId,
        name: step.deviceInfo.name,
        platform: step.deviceInfo.platform,
        operatingSystem: step.deviceInfo.operatingSystem,
      };

      const isAuthorizedDevice =
        connection.device.deviceId in readState.bridge.authorizedDevices;

      if (!isAuthorizedDevice) {
        authStates.set(connection.device.deviceId, {
          step: "awaiting_user_confirmation",
          device,
        });

        requestAuthorizationFromUser(connection, device);
      } else {
        const { publicKey } =
          readState.bridge.authorizedDevices[connection.device.deviceId];

        const challenge = new Uint8Array(32);

        await globals.crypto.getRandomValues(challenge);

        const hash = new Uint8Array(
          await globals.crypto.subtle.digest({ name: "SHA-1" }, challenge),
        );

        const cryptoKey = await globals.crypto.subtle.importKey(
          "spki",
          Uint8Array.from(publicKey),
          {
            name: "RSA-OAEP",
            hash: "SHA-256",
          },
          false,
          ["encrypt"],
        );

        const encryptedData = await globals.crypto.subtle.encrypt(
          {
            name: "RSA-OAEP",
          },
          cryptoKey,
          challenge,
        );

        authStates.set(connection.device.deviceId, {
          step: "awaiting_answer",
          device,
          challenge,
        });

        bridgingMessageChannel.send(
          connection,
          new BridgingStepChallenge({
            challenge: new Uint8Array(encryptedData),
            hash,
          }),
        );
      }
    } else if (step instanceof BridgingStepPublicKey) {
      if (state.step !== "waiting_for_public_key") {
        throw "Unexpected public key";
      }

      devLog("[bridge] Connection authorized, bridging successful", {
        deviceId: connection.device.deviceId,
        device: state.device,
      });

      addAuthorizedDevice(connection.device.deviceId, {
        publicKey: Array.from(step.publicKey),

        device: state.device,
      });

      bridge.setDeviceAuthorized(connection.device.deviceId);

      authStates.delete(connection.device.deviceId);

      showAuthorizedSnackbar(connection.device.deviceId, true, state.device);
    } else if (step instanceof BridgingStepAnswer) {
      if (state.step !== "awaiting_answer") {
        throw "Unexpected answer";
      }

      // If the result was `null`, or any of the bytes in the result were different from the initial challenge
      if (
        step.result === null ||
        !state.challenge.every((v, i) => v === step.result[i])
      ) {
        devLog(
          `[bridge] Device failed the challenge, removing from authorized devices`,
          { deviceId: connection.device.deviceId, device: state.device },
        );

        removeAuthorizedDevice(connection.device.deviceId);

        requestAuthorizationFromUser(connection, state.device);
      } else {
        devLog("[bridge] Connection authorized, bridging successful", {
          deviceId: connection.device.deviceId,
          device: state.device,
        });

        updateAuthorizedDevice(connection.device.deviceId, state.device);

        bridge.setDeviceAuthorized(connection.device.deviceId);

        authStates.delete(connection.device.deviceId);

        showAuthorizedSnackbar(connection.device.deviceId, false, state.device);
      }
    } else {
      devWarn("[bridge] Received unexpected message", data);
    }
  } catch (e) {
    devWarn(`[bridge] Bridging failed: ${e}`, data);

    connection.close();
  }
});

function generateConfirmationCode(): string {
  let adjective: string;
  let noun: string;

  for (let i = 0; i < 10; i++) {
    [adjective, noun] = [adjectives, nouns].map((list) => {
      return list[Math.floor(Math.random() * list.length)];
    });

    if (adjective !== noun) {
      break;
    }
  }

  return `${adjective} ${noun} ${Math.floor(Math.random() * 1000)}`;
}

function requestAuthorizationFromUser(
  connection: IConnection,
  device: BridgeDeviceInfo,
) {
  authStates.set(connection.device.deviceId, {
    step: "awaiting_user_confirmation",
    device,
  });

  const confirmationCode = generateConfirmationCode();

  bridgingMessageChannel.send(
    connection,
    new BridgingStepUserConfirmation({ code: confirmationCode }),
  );

  showConfirmationSnackbar(
    connection.device.deviceId,
    confirmationCode,
    device,
    {
      onAllow: () => {
        authStates.set(connection.device.deviceId, {
          step: "waiting_for_public_key",
          device,
        });

        bridgingMessageChannel.send(connection, new BridgingStepAuthorized());
      },
      onDeny: () => {
        connection.close();
      },
    },
  );
}

function showAuthorizedSnackbar(
  deviceId: string,
  isNewAuthorization: boolean,
  device: BridgeDeviceInfo,
) {
  showSnackbar({
    priority: "high",
    id: `bridging-authorized-${deviceId}`,
    closeAfter: 5000,
    Icon: BlitzLogoIcon,
    text: [
      isNewAuthorization ? "bridge:connected.new" : "bridge:connected.again",
      isNewAuthorization
        ? `Connection established with {{deviceName}}`
        : `Connection re-established with {{deviceName}}`,
      { deviceName: device.name },
    ],
  });
}

function showConfirmationSnackbar(
  deviceId: string,
  confirmationCode: string,
  device: BridgeDeviceInfo,
  {
    onAllow,
    onDeny,
  }: {
    onAllow: () => void;
    onDeny: () => void;
  },
) {
  showSnackbar({
    priority: "high",
    id: `bridging-confirmation-${deviceId}`,
    Icon: BlitzLogoIcon,
    dismissable: false,
    text: [
      "bridge:authorization.prompt",
      `{{deviceName}} wishes to connect: {{confirmationCode}}`,
      { deviceName: device.name, confirmationCode },
    ],
    actions: [
      {
        text: ["bridge:authorization.allow", "Allow"],
        onClick: onAllow,
      },
      {
        emphasis: "medium",
        text: ["bridge:authorization.deny", "Deny"],
        onClick: onDeny,
      },
    ],
  });
}

function removeSnackbars(deviceId: string) {
  removeSnackbarMessage(`bridging-authorized-${deviceId}`);
  removeSnackbarMessage(`bridging-confirmation-${deviceId}`);
}
