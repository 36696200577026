// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

import {
  DiscoveryPacket,
  unionListToDiscoveryPacket,
  unionToDiscoveryPacket,
} from "@/feature-bridge/idl/discovery_packet/discovery-packet.mjs";

export class DiscoveryMessage {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): DiscoveryMessage {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsDiscoveryMessage(
    bb: flatbuffers.ByteBuffer,
    obj?: DiscoveryMessage,
  ): DiscoveryMessage {
    return (obj || new DiscoveryMessage()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsDiscoveryMessage(
    bb: flatbuffers.ByteBuffer,
    obj?: DiscoveryMessage,
  ): DiscoveryMessage {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new DiscoveryMessage()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  packetType(): DiscoveryPacket {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb_pos + offset)
      : DiscoveryPacket.NONE;
  }

  packet<T extends flatbuffers.Table>(obj: any): any | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
  }

  static startDiscoveryMessage(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addPacketType(
    builder: flatbuffers.Builder,
    packetType: DiscoveryPacket,
  ) {
    builder.addFieldInt8(0, packetType, DiscoveryPacket.NONE);
  }

  static addPacket(
    builder: flatbuffers.Builder,
    packetOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, packetOffset, 0);
  }

  static endDiscoveryMessage(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 6); // packet
    return offset;
  }

  static finishDiscoveryMessageBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset);
  }

  static finishSizePrefixedDiscoveryMessageBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset, undefined, true);
  }

  static createDiscoveryMessage(
    builder: flatbuffers.Builder,
    packetType: DiscoveryPacket,
    packetOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    DiscoveryMessage.startDiscoveryMessage(builder);
    DiscoveryMessage.addPacketType(builder, packetType);
    DiscoveryMessage.addPacket(builder, packetOffset);
    return DiscoveryMessage.endDiscoveryMessage(builder);
  }
}
