// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class BridgeMessage {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BridgeMessage {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBridgeMessage(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgeMessage,
  ): BridgeMessage {
    return (obj || new BridgeMessage()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBridgeMessage(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgeMessage,
  ): BridgeMessage {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BridgeMessage()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  channel(): string | null;
  channel(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  channel(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  data(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  dataLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  dataArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  static startBridgeMessage(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addChannel(
    builder: flatbuffers.Builder,
    channelOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, channelOffset, 0);
  }

  static addData(builder: flatbuffers.Builder, dataOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, dataOffset, 0);
  }

  static createDataVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startDataVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(1, numElems, 1);
  }

  static endBridgeMessage(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // channel
    return offset;
  }

  static finishBridgeMessageBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset);
  }

  static finishSizePrefixedBridgeMessageBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset, undefined, true);
  }

  static createBridgeMessage(
    builder: flatbuffers.Builder,
    channelOffset: flatbuffers.Offset,
    dataOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    BridgeMessage.startBridgeMessage(builder);
    BridgeMessage.addChannel(builder, channelOffset);
    BridgeMessage.addData(builder, dataOffset);
    return BridgeMessage.endBridgeMessage(builder);
  }
}
