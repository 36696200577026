import type { Bridge } from "@/feature-bridge/bridge.mjs";
import type {
  IConnection,
  SendableData,
} from "@/feature-bridge/connection.mjs";
import { StrictEventEmitter } from "@/util/strict-event-emitter.mjs";

type BridgeMessageChannelEvents = {
  message(args: { connection: IConnection; channel: string; data: Uint8Array });
};

export type BridgeMessageChannelArgs = {
  name: string;
  authorized?: boolean;
};

export class BridgeMessageChannel extends StrictEventEmitter<BridgeMessageChannelEvents> {
  #name: string;

  constructor(
    bridge: Bridge,
    { name, authorized = true }: BridgeMessageChannelArgs,
  ) {
    super();

    this.#name = name;

    bridge.on(
      authorized ? "authorizedMessage" : "unauthorizedMessage",
      ({ connection, channel, data }) => {
        if (channel !== this.#name) return;

        this.emit("message", { connection, channel, data });
      },
    );
  }

  get name() {
    return this.#name;
  }

  async send(connection: IConnection, data?: SendableData) {
    await connection.send(this.#name, data);
  }
}
