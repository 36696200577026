// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import { RpcRequest } from "@/feature-bridge/idl/rpc_message/rpc-request.mjs";
import { RpcResponse } from "@/feature-bridge/idl/rpc_message/rpc-response.mjs";

export enum RpcBody {
  NONE = 0,
  Request = 1,
  Response = 2,
}

export function unionToRpcBody(
  type: RpcBody,
  accessor: (obj: RpcRequest | RpcResponse) => RpcRequest | RpcResponse | null,
): RpcRequest | RpcResponse | null {
  switch (RpcBody[type]) {
    case "NONE":
      return null;
    case "Request":
      return accessor(new RpcRequest())! as RpcRequest;
    case "Response":
      return accessor(new RpcResponse())! as RpcResponse;
    default:
      return null;
  }
}

export function unionListToRpcBody(
  type: RpcBody,
  accessor: (
    index: number,
    obj: RpcRequest | RpcResponse,
  ) => RpcRequest | RpcResponse | null,
  index: number,
): RpcRequest | RpcResponse | null {
  switch (RpcBody[type]) {
    case "NONE":
      return null;
    case "Request":
      return accessor(index, new RpcRequest())! as RpcRequest;
    case "Response":
      return accessor(index, new RpcResponse())! as RpcResponse;
    default:
      return null;
  }
}
