// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class BridgingAuthorized {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BridgingAuthorized {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBridgingAuthorized(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingAuthorized,
  ): BridgingAuthorized {
    return (obj || new BridgingAuthorized()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBridgingAuthorized(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingAuthorized,
  ): BridgingAuthorized {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BridgingAuthorized()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static startBridgingAuthorized(builder: flatbuffers.Builder) {
    builder.startObject(0);
  }

  static endBridgingAuthorized(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createBridgingAuthorized(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    BridgingAuthorized.startBridgingAuthorized(builder);
    return BridgingAuthorized.endBridgingAuthorized(builder);
  }
}
