// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export { BlitzConnectCandidate } from "@/feature-bridge/idl/blitzconnect_message/blitz-connect-candidate.mjs";
export { BlitzConnectMessage } from "@/feature-bridge/idl/blitzconnect_message/blitz-connect-message.mjs";
export {
  BlitzConnectPacket,
  unionToBlitzConnectPacket,
} from "@/feature-bridge/idl/blitzconnect_message/blitz-connect-packet.mjs";
export { BlitzConnectSignal } from "@/feature-bridge/idl/blitzconnect_message/blitz-connect-signal.mjs";
