// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class RpcResponse {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): RpcResponse {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsRpcResponse(
    bb: flatbuffers.ByteBuffer,
    obj?: RpcResponse,
  ): RpcResponse {
    return (obj || new RpcResponse()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsRpcResponse(
    bb: flatbuffers.ByteBuffer,
    obj?: RpcResponse,
  ): RpcResponse {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new RpcResponse()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  correlationId(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  result(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  resultLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  resultArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  static startRpcResponse(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addCorrelationId(builder: flatbuffers.Builder, correlationId: number) {
    builder.addFieldInt32(0, correlationId, 0);
  }

  static addResult(
    builder: flatbuffers.Builder,
    resultOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, resultOffset, 0);
  }

  static createResultVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startResultVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(1, numElems, 1);
  }

  static endRpcResponse(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 6); // result
    return offset;
  }

  static createRpcResponse(
    builder: flatbuffers.Builder,
    correlationId: number,
    resultOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    RpcResponse.startRpcResponse(builder);
    RpcResponse.addCorrelationId(builder, correlationId);
    RpcResponse.addResult(builder, resultOffset);
    return RpcResponse.endRpcResponse(builder);
  }
}
