// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class BlitzConnectCandidate {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BlitzConnectCandidate {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBlitzConnectCandidate(
    bb: flatbuffers.ByteBuffer,
    obj?: BlitzConnectCandidate,
  ): BlitzConnectCandidate {
    return (obj || new BlitzConnectCandidate()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBlitzConnectCandidate(
    bb: flatbuffers.ByteBuffer,
    obj?: BlitzConnectCandidate,
  ): BlitzConnectCandidate {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BlitzConnectCandidate()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  candidate(): string | null;
  candidate(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  candidate(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  sdpMid(): string | null;
  sdpMid(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  sdpMid(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  sdpMLineIndex(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  static startBlitzConnectCandidate(builder: flatbuffers.Builder) {
    builder.startObject(3);
  }

  static addCandidate(
    builder: flatbuffers.Builder,
    candidateOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, candidateOffset, 0);
  }

  static addSdpMid(
    builder: flatbuffers.Builder,
    sdpMidOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, sdpMidOffset, 0);
  }

  static addSdpMLineIndex(builder: flatbuffers.Builder, sdpMLineIndex: number) {
    builder.addFieldInt32(2, sdpMLineIndex, 0);
  }

  static endBlitzConnectCandidate(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // candidate
    builder.requiredField(offset, 6); // sdp_mid
    return offset;
  }

  static createBlitzConnectCandidate(
    builder: flatbuffers.Builder,
    candidateOffset: flatbuffers.Offset,
    sdpMidOffset: flatbuffers.Offset,
    sdpMLineIndex: number,
  ): flatbuffers.Offset {
    BlitzConnectCandidate.startBlitzConnectCandidate(builder);
    BlitzConnectCandidate.addCandidate(builder, candidateOffset);
    BlitzConnectCandidate.addSdpMid(builder, sdpMidOffset);
    BlitzConnectCandidate.addSdpMLineIndex(builder, sdpMLineIndex);
    return BlitzConnectCandidate.endBlitzConnectCandidate(builder);
  }
}
