// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class BridgingUserConfirmation {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BridgingUserConfirmation {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsBridgingUserConfirmation(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingUserConfirmation,
  ): BridgingUserConfirmation {
    return (obj || new BridgingUserConfirmation()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsBridgingUserConfirmation(
    bb: flatbuffers.ByteBuffer,
    obj?: BridgingUserConfirmation,
  ): BridgingUserConfirmation {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new BridgingUserConfirmation()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  code(): string | null;
  code(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  code(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  static startBridgingUserConfirmation(builder: flatbuffers.Builder) {
    builder.startObject(1);
  }

  static addCode(builder: flatbuffers.Builder, codeOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, codeOffset, 0);
  }

  static endBridgingUserConfirmation(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // code
    return offset;
  }

  static createBridgingUserConfirmation(
    builder: flatbuffers.Builder,
    codeOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    BridgingUserConfirmation.startBridgingUserConfirmation(builder);
    BridgingUserConfirmation.addCode(builder, codeOffset);
    return BridgingUserConfirmation.endBridgingUserConfirmation(builder);
  }
}
