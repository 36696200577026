// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum BridgeDevicePlatform {
  Unknown = 0,
  Android = 1,
  iOS = 2,
  Windows = 3,
  MacOS = 4,
  Linux = 5,
  Web = 6,
  Overlay = 7,
}
