import { v4 } from "uuid";

import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  isVolatile,
  readState,
} from "@/__main__/app-state.mjs";
import { IS_NODE, isPersistent, MAX_TIME } from "@/__main__/constants.mjs";
import { readData } from "@/__main__/get-data.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import bridge from "@/feature-bridge/bridge.mjs";
import type { IConnection } from "@/feature-bridge/connection.mjs";
import type {
  BridgeAuthorizedDevice,
  BridgeDeviceInfo,
} from "@/feature-bridge/constants.mjs";
import clone from "@/util/clone.mjs";
import { devDebug } from "@/util/dev.mjs";

export function addConnection(connection: IConnection) {
  bridge.addConnection(connection);

  const connections = clone(readState.bridge.connections);
  connections.push(connection.device.deviceId);
  connections[isVolatile] = true;
  writeState.bridge.connections = connections;

  connection.on("close", () => {
    delete writeState.bridge.connections[connection.device.deviceId];
    const connections = clone(readState.bridge.connections);
    connections.splice(connections.indexOf(connection.device.deviceId), 1);
    connections[isVolatile] = true;
    writeState.bridge.connections = connections;
  });
}

export function addAuthorizedDevice(
  deviceId: string,
  authorizedDevice: BridgeAuthorizedDevice,
) {
  const authorizedDevices = clone(readState.bridge.authorizedDevices);
  authorizedDevices[deviceId] = authorizedDevice;
  authorizedDevices[isPersistent] = MAX_TIME;
  writeState.bridge.authorizedDevices = authorizedDevices;
}

export function updateAuthorizedDevice(
  deviceId: string,
  device: BridgeDeviceInfo,
) {
  const authorizedDevices = clone(readState.bridge.authorizedDevices);
  authorizedDevices[deviceId].device = device;
  authorizedDevices[isPersistent] = MAX_TIME;
  writeState.bridge.authorizedDevices = authorizedDevices;
}

export function removeAuthorizedDevice(deviceId: string) {
  const authorizedDevices = clone(readState.bridge.authorizedDevices);
  delete authorizedDevices[deviceId];
  authorizedDevices[isPersistent] = MAX_TIME;
  writeState.bridge.authorizedDevices = authorizedDevices;
}

export function showBlitzConnectPopup() {
  setVolatileKV(
    "blitzConnectPopupVisible",
    !readState.volatile.blitzConnectPopupVisible,
  );
}

let ensureDevicePromise: Promise<void>;

export async function ensureDevice() {
  if (IS_NODE) return;

  if (!ensureDevicePromise) {
    ensureDevicePromise = (async () => {
      let device =
        (await blitzMessage(EVENTS.BRIDGE_DEVICE_INFO)) ??
        (await readData(["bridge", "device"]));

      await readData(["bridge", "authorizedDevices"]);

      if (!device) {
        const agent = navigator.userAgent.toLowerCase();
        const browserName =
          agent.indexOf("edge") > -1
            ? "Edge"
            : agent.indexOf("edg") > -1
            ? "Edge (Chromium)"
            : // eslint-disable-next-line no-restricted-properties
            agent.indexOf("opr") > -1 && window["opr"]
            ? "Opera"
            : // eslint-disable-next-line no-restricted-properties
            agent.indexOf("chrome") > -1 && window["chrome"]
            ? "Chrome"
            : agent.indexOf("trident") > -1
            ? "IE"
            : agent.indexOf("firefox") > -1
            ? "Firefox"
            : agent.indexOf("safari") > -1
            ? "Safari"
            : "Browser";

        device = {
          deviceId: v4(),
          name: browserName,
          platform: "web",
          operatingSystem: "Unknown",
        };

        devDebug("[bridge] Generated new device ID:", device.deviceId);
      }

      device[isPersistent] = MAX_TIME;

      writeState.bridge.device = device;
    })();
  }

  await ensureDevicePromise;
}

declare global {
  interface BlitzEvents {
    readonly BRIDGE_DEVICE_INFO: unique symbol;
    readonly BRIDGE_IPC_MESSAGE: unique symbol;
  }

  interface BlitzMessaging {
    blitzMessage(
      type: BlitzEvents["BRIDGE_DEVICE_INFO"],
    ): Promise<BridgeDeviceInfo>;

    blitzMessage(
      type: BlitzEvents["BRIDGE_IPC_MESSAGE"],
      payload: { channel: string; data: Uint8Array },
    );

    handleMessage(
      type: BlitzEvents["BRIDGE_IPC_MESSAGE"],
      handler: (payload: { channel: string; data: Uint8Array }) => void,
    );
  }
}
