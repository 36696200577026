/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from "flatbuffers";

export enum LcuRequestMethod {
  GET = 0,
  POST = 1,
  PUT = 2,
  PATCH = 3,
  DELETE = 4,
}

export class LcuRequest {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): LcuRequest {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsLcuRequest(
    bb: flatbuffers.ByteBuffer,
    obj?: LcuRequest,
  ): LcuRequest {
    return (obj || new LcuRequest()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsLcuRequest(
    bb: flatbuffers.ByteBuffer,
    obj?: LcuRequest,
  ): LcuRequest {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new LcuRequest()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  method(): LcuRequestMethod {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb_pos + offset)
      : LcuRequestMethod.GET;
  }

  uri(): string | null;
  uri(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  uri(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  body(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  bodyLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  bodyArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  static startLcuRequest(builder: flatbuffers.Builder) {
    builder.startObject(3);
  }

  static addMethod(builder: flatbuffers.Builder, method: LcuRequestMethod) {
    builder.addFieldInt8(0, method, LcuRequestMethod.GET);
  }

  static addUri(builder: flatbuffers.Builder, uriOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, uriOffset, 0);
  }

  static addBody(builder: flatbuffers.Builder, bodyOffset: flatbuffers.Offset) {
    builder.addFieldOffset(2, bodyOffset, 0);
  }

  static createBodyVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startBodyVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(1, numElems, 1);
  }

  static endLcuRequest(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 6); // uri
    return offset;
  }

  static createLcuRequest(
    builder: flatbuffers.Builder,
    method: LcuRequestMethod,
    uriOffset: flatbuffers.Offset,
    bodyOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    LcuRequest.startLcuRequest(builder);
    LcuRequest.addMethod(builder, method);
    LcuRequest.addUri(builder, uriOffset);
    LcuRequest.addBody(builder, bodyOffset);
    return LcuRequest.endLcuRequest(builder);
  }
}
