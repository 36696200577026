import * as flatbuffers from "flatbuffers";

import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import type { BridgeDeviceInfo } from "@/feature-bridge/constants.mjs";
import * as bridgeMessageIdl from "@/feature-bridge/idl/bridge_message/bridge_message.mjs";
import * as idl from "@/feature-bridge/idl/webrtc_message/webrtc_message.mjs";
import { devDebug, devError } from "@/util/dev.mjs";
import type { StrictEventMappings } from "@/util/strict-event-emitter.mjs";
import { StrictEventEmitter } from "@/util/strict-event-emitter.mjs";

export type SendableData = Uint8Array | { asBytes(): Uint8Array };

export interface BridgeMessage {
  channel: string;
  data: Uint8Array;
}

type IConnectionEvents = {
  close();

  data(msg: BridgeMessage);
  error(err: Event);
};

export abstract class IConnection<
  Events extends StrictEventMappings = never,
> extends StrictEventEmitter<Events, IConnectionEvents> {
  abstract isOpen: boolean;
  abstract isReady: boolean;

  abstract requiresAuthentication: boolean;

  abstract device: BridgeDeviceInfo;

  abstract send(channel: string, data: SendableData): Promise<void> | void;

  abstract close(): void;
}

export class WebRTCConnectionBuilder {
  deviceId?: string;

  device?: BridgeDeviceInfo;

  #connection: RTCPeerConnection;

  #candidateChannel: RTCDataChannel;
  #dataChannel: RTCDataChannel;

  constructor(
    connection: RTCPeerConnection,
    candidateChannel: RTCDataChannel,
    dataChannel: RTCDataChannel,
  ) {
    this.#connection = connection;

    this.#candidateChannel = candidateChannel;
    this.#dataChannel = dataChannel;
  }

  static create(): WebRTCConnectionBuilder {
    const connection = new RTCPeerConnection({
      iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }],
    });

    const candidateChannel = connection.createDataChannel("candidate", {
      negotiated: true,
      id: 0,
    });

    const dataChannel = connection.createDataChannel("data", {
      negotiated: true,
      id: 1,
    });

    return new WebRTCConnectionBuilder(
      connection,
      candidateChannel,
      dataChannel,
    );
  }

  async createOffer() {
    const offer = await this.#connection.createOffer({});

    await this.#connection.setLocalDescription(offer);

    return offer.sdp;
  }

  close() {
    this.#connection.close();
  }

  finalize(): WebRTCConnection {
    if (!this.device) {
      throw new Error("Cannot finalize connection without device info");
    }

    return new WebRTCConnection(
      this.device,
      this.#connection,
      this.#candidateChannel,
      this.#dataChannel,
    );
  }
}

type WebRTCConnectionEvents = {
  candidate(candidate: RTCIceCandidate);

  ready();
};

export class WebRTCConnection extends IConnection<WebRTCConnectionEvents> {
  device: BridgeDeviceInfo = null;

  #hasClosed = false;

  #connection: RTCPeerConnection;

  #candidateChannel: RTCDataChannel;
  #dataChannel: RTCDataChannel;

  get isOpen() {
    return this.#connection.connectionState === "connected";
  }

  get isClosed() {
    return ["closed", "disconnected", "failed"].includes(
      this.#connection.connectionState,
    );
  }

  get requiresAuthentication() {
    return true;
  }

  get isReady() {
    return this.isOpen && this.#dataChannel.readyState === "open";
  }

  constructor(
    device: BridgeDeviceInfo,
    connection: RTCPeerConnection,
    candidateChannel: RTCDataChannel,
    dataChannel: RTCDataChannel,
  ) {
    super();

    this.device = device;

    this.#connection = connection;
    this.#candidateChannel = candidateChannel;
    this.#dataChannel = dataChannel;

    this.#connection.onconnectionstatechange = () => {
      if (this.isClosed) {
        this.close();
      }
    };

    this.#connection.oniceconnectionstatechange = () => {
      if (this.isClosed) {
        // If the app disconnects itself from the client while it's not actively listening (such as it being in the background),
        // it will not fully close the connection on its end. This means when the client comes to the foreground it will attempt
        // to send messages to the app, which causes the ice connection flow to come alive again (due to how webrtc works), but
        // no real communication will ever occur since the app has already marked the connection as closed.
        //
        // This will ensure we close the connection if and when the client attempts to talk to a connection that has already
        // been closed.
        this.#connection.close();
      }
    };

    connection.onicecandidate = (event) => {
      if (!event.candidate) return;

      this.emit("candidate", event.candidate);

      if (this.isReady) {
        devDebug("[bridge] Found new candidate, sending", {
          deviceId: this.device.deviceId,
        });

        const builder = new flatbuffers.Builder();

        idl.WebRtcCandidate.createWebRtcCandidate(
          builder,
          builder.createString(event.candidate.candidate),
          builder.createString(event.candidate.sdpMid),
          event.candidate.sdpMLineIndex,
        );

        this.#candidateChannel.send(builder.asUint8Array());
      }
    };

    this.#candidateChannel.onmessage = (event) => {
      if (this.#hasClosed) {
        return;
      }

      devDebug("[bridge] Received new candidate, adding", {
        deviceId: this.device.deviceId,
      });

      const candidate = idl.WebRtcCandidate.getRootAsWebRtcCandidate(
        new flatbuffers.ByteBuffer(event.data),
      );

      this.#connection.addIceCandidate({
        candidate: candidate.candidate(),
        sdpMid: candidate.sdpMid(),
        sdpMLineIndex: candidate.sdpMLineIndex(),
      });
    };

    this.#dataChannel.onopen = () => {
      devDebug("[bridge] Data channel open", {
        deviceId: this.device.deviceId,
      });

      this.emit("ready");
    };

    this.#dataChannel.onclose = () => {
      devDebug("[bridge] Data channel closed", {
        deviceId: this.device.deviceId,
      });

      this.close();
    };

    this.#dataChannel.onmessage = (event) => {
      if (this.#hasClosed) {
        return;
      }

      const message = bridgeMessageIdl.BridgeMessage.getRootAsBridgeMessage(
        new flatbuffers.ByteBuffer(new Uint8Array(event.data)),
      );

      this.emit("data", {
        channel: message.channel(),
        data: message.dataArray(),
      });
    };

    this.#dataChannel.onerror = (err) => {
      if (err instanceof RTCErrorEvent) {
        if (err.error.message === "User-Initiated Abort, reason=Close called") {
          this.close();

          return;
        }
      }

      devError("[bridge] Data channel error", {
        err,
        deviceId: this.device.deviceId,
      });

      this.emit("error", err);
    };
  }

  send(channel: string, data: SendableData): Promise<void> | void {
    if (this.isClosed) {
      throw new Error("Connection has been closed");
    }

    if (!this.isReady) {
      throw new Error("Connection is not ready");
    }

    const builder = new flatbuffers.Builder();

    builder.finish(
      bridgeMessageIdl.BridgeMessage.createBridgeMessage(
        builder,
        builder.createString(channel),
        bridgeMessageIdl.BridgeMessage.createDataVector(
          builder,
          data instanceof Uint8Array ? data : data.asBytes(),
        ),
      ),
    );

    this.#dataChannel.send(builder.asUint8Array());
  }

  close() {
    if (this.#hasClosed) {
      return;
    }

    this.#hasClosed = true;

    devDebug("[bridge] Closing connection", {
      deviceId: this.device.deviceId,
    });

    if (!this.isClosed) {
      this.#connection.close();
    }

    this.emit("close");
  }

  async createAnswer(offerSdp: string) {
    await this.#connection.setRemoteDescription({
      sdp: offerSdp,
      type: "offer",
    });

    const answer = await this.#connection.createAnswer({});

    await this.#connection.setLocalDescription(answer);

    return answer.sdp;
  }

  async onReceivedAnswer(offerSdp: string) {
    await this.#connection.setRemoteDescription({
      sdp: offerSdp,
      type: "answer",
    });
  }

  async onReceivedCandidate(candidate: RTCIceCandidateInit) {
    await this.#connection.addIceCandidate(candidate);
  }
}

export class IpcConnectionBuilder {
  deviceId: string;

  constructor(deviceId: string) {
    this.deviceId = deviceId;
  }

  finalize(device: BridgeDeviceInfo): IpcConnection {
    return new IpcConnection(device);
  }
}

export class IpcConnection extends IConnection {
  device: BridgeDeviceInfo;

  #isReady = false;
  #hasClosed = false;

  get isOpen() {
    return !this.#hasClosed;
  }

  get isClosed() {
    return this.#hasClosed;
  }

  get requiresAuthentication() {
    return false;
  }

  get isReady() {
    return this.#isReady;
  }

  constructor(device: BridgeDeviceInfo) {
    super();

    this.device = device;
  }

  async send(channel: string, data: SendableData) {
    await blitzMessage(EVENTS.BRIDGE_IPC_MESSAGE, {
      channel: channel,
      data: data instanceof Uint8Array ? data : data.asBytes(),
    });
  }

  close(): void {
    this.emit("close");
  }
}
