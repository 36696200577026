/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from "flatbuffers";

export enum LcuEventType {
  Create = 0,
  Update = 1,
  Delete = 2,
}

export class LcuEvent {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): LcuEvent {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsLcuEvent(
    bb: flatbuffers.ByteBuffer,
    obj?: LcuEvent,
  ): LcuEvent {
    return (obj || new LcuEvent()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsLcuEvent(
    bb: flatbuffers.ByteBuffer,
    obj?: LcuEvent,
  ): LcuEvent {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new LcuEvent()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  eventType(): LcuEventType {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb_pos + offset)
      : LcuEventType.Create;
  }

  uri(): string | null;
  uri(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  uri(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  data(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  dataLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  dataArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  static startLcuEvent(builder: flatbuffers.Builder) {
    builder.startObject(3);
  }

  static addEventType(builder: flatbuffers.Builder, eventType: LcuEventType) {
    builder.addFieldInt8(0, eventType, LcuEventType.Create);
  }

  static addUri(builder: flatbuffers.Builder, uriOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, uriOffset, 0);
  }

  static addData(builder: flatbuffers.Builder, dataOffset: flatbuffers.Offset) {
    builder.addFieldOffset(2, dataOffset, 0);
  }

  static createDataVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startDataVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(1, numElems, 1);
  }

  static endLcuEvent(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 6); // uri
    builder.requiredField(offset, 8); // data
    return offset;
  }

  static createLcuEvent(
    builder: flatbuffers.Builder,
    eventType: LcuEventType,
    uriOffset: flatbuffers.Offset,
    dataOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    LcuEvent.startLcuEvent(builder);
    LcuEvent.addEventType(builder, eventType);
    LcuEvent.addUri(builder, uriOffset);
    LcuEvent.addData(builder, dataOffset);
    return LcuEvent.endLcuEvent(builder);
  }
}

export class LcuEvents {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): LcuEvents {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsLcuEvents(
    bb: flatbuffers.ByteBuffer,
    obj?: LcuEvents,
  ): LcuEvents {
    return (obj || new LcuEvents()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsLcuEvents(
    bb: flatbuffers.ByteBuffer,
    obj?: LcuEvents,
  ): LcuEvents {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new LcuEvents()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  events(index: number, obj?: LcuEvent): LcuEvent | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? (obj || new LcuEvent()).__init(
          this.bb!.__indirect(
            this.bb!.__vector(this.bb_pos + offset) + index * 4,
          ),
          this.bb!,
        )
      : null;
  }

  eventsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  static startLcuEvents(builder: flatbuffers.Builder) {
    builder.startObject(1);
  }

  static addEvents(
    builder: flatbuffers.Builder,
    eventsOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, eventsOffset, 0);
  }

  static createEventsVector(
    builder: flatbuffers.Builder,
    data: flatbuffers.Offset[],
  ): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startEventsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static endLcuEvents(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // events
    return offset;
  }

  static createLcuEvents(
    builder: flatbuffers.Builder,
    eventsOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    LcuEvents.startLcuEvents(builder);
    LcuEvents.addEvents(builder, eventsOffset);
    return LcuEvents.endLcuEvents(builder);
  }
}
