// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "flatbuffers";

export class DiscoveryInquiry {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): DiscoveryInquiry {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsDiscoveryInquiry(
    bb: flatbuffers.ByteBuffer,
    obj?: DiscoveryInquiry,
  ): DiscoveryInquiry {
    return (obj || new DiscoveryInquiry()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsDiscoveryInquiry(
    bb: flatbuffers.ByteBuffer,
    obj?: DiscoveryInquiry,
  ): DiscoveryInquiry {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new DiscoveryInquiry()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  derivedHash(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  derivedHashLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  derivedHashArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  static startDiscoveryInquiry(builder: flatbuffers.Builder) {
    builder.startObject(1);
  }

  static addDerivedHash(
    builder: flatbuffers.Builder,
    derivedHashOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, derivedHashOffset, 0);
  }

  static createDerivedHashVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startDerivedHashVector(
    builder: flatbuffers.Builder,
    numElems: number,
  ) {
    builder.startVector(1, numElems, 1);
  }

  static endDiscoveryInquiry(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // derived_hash
    return offset;
  }

  static createDiscoveryInquiry(
    builder: flatbuffers.Builder,
    derivedHashOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    DiscoveryInquiry.startDiscoveryInquiry(builder);
    DiscoveryInquiry.addDerivedHash(builder, derivedHashOffset);
    return DiscoveryInquiry.endDiscoveryInquiry(builder);
  }
}
