// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import type * as flatbuffers from "flatbuffers";

export class BridgeDeviceId {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): BridgeDeviceId {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  lowBytes(): bigint {
    return this.bb!.readUint64(this.bb_pos);
  }

  highBytes(): bigint {
    return this.bb!.readUint64(this.bb_pos + 8);
  }

  static sizeOf(): number {
    return 16;
  }

  static createBridgeDeviceId(
    builder: flatbuffers.Builder,
    low_bytes: bigint,
    high_bytes: bigint,
  ): flatbuffers.Offset {
    builder.prep(8, 16);
    builder.writeInt64(BigInt(high_bytes ?? 0));
    builder.writeInt64(BigInt(low_bytes ?? 0));
    return builder.offset();
  }
}
