import { showSnackbar } from "@/app/actions.mjs";
import { devDebug } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";

export const bridgeKodeRefs: {
  onKodeCompleted?: () => void | null;
} = { onKodeCompleted: null };

const BURNING_FUMES_SEQUENCE = [
  0x52, 0x4f, 0x43, 0x4b, 0x45, 0x54, 0x4d, 0x41, 0x4e,
];

let currentIndex = 0;

function onKeyDownEvent({ keyCode }: KeyboardEvent) {
  if (BURNING_FUMES_SEQUENCE[currentIndex] !== keyCode) {
    currentIndex = 0;
    return;
  }
  currentIndex++;
  if (currentIndex === BURNING_FUMES_SEQUENCE.length) {
    complete();
    currentIndex = 0;
  }
}

function complete() {
  devDebug("network bridge activated 🚀🚀🚀");

  showSnackbar({
    priority: "high",
    id: `bridge-activated`,
    icon: "🚀",
    text: ["", "Network bridge activated!"],
    dismissable: false,
    closeAfter: 5000,
  });

  bridgeKodeRefs.onKodeCompleted?.();
}

export function enableBridgeKode() {
  if (typeof globals.addEventListener === "function") {
    globals.addEventListener("keydown", onKeyDownEvent);
  }
}

export function disableBridgeKode() {
  if (typeof globals.addEventListener === "function") {
    globals.removeEventListener("keydown", onKeyDownEvent);
  }
}
