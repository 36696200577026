import * as flatbuffers from "flatbuffers";
import { parse as uuidToBytes, stringify as bytesToUuid } from "uuid";

import type { BridgeDevicePlatform } from "@/feature-bridge/constants.mjs";
import {
  BRIDGE_DEVICE_PLATFORM_MAPPING,
  BRIDGE_DEVICE_REVERSE_PLATFORM_MAPPING,
} from "@/feature-bridge/constants.mjs";
import * as idl from "@/feature-bridge/idl/bridge_device/bridge_device.mjs";

export class BridgeDeviceInfoData {
  deviceId: string;
  name: string;
  platform: BridgeDevicePlatform;
  operatingSystem: string;

  constructor(props: NonMethods<BridgeDeviceInfoData>) {
    Object.assign(this, props);
  }

  static from(deviceInfo: idl.BridgeDeviceInfo): BridgeDeviceInfoData {
    const deviceId = deviceInfo.deviceId();

    if (deviceId === null) {
      throw new Error("Signal is missing a device id");
    }

    return new BridgeDeviceInfoData({
      deviceId: lowHighToDeviceId(deviceId.lowBytes(), deviceId.highBytes()),
      name: deviceInfo.name() ?? "Unknown",
      platform:
        BRIDGE_DEVICE_PLATFORM_MAPPING[deviceInfo.platform() ?? "unknown"] ??
        "unknown",
      operatingSystem: deviceInfo.operatingSystem() ?? "Unknown",
    });
  }

  static parse(bytes: Uint8Array) {
    return BridgeDeviceInfoData.from(
      idl.BridgeDeviceInfo.getRootAsBridgeDeviceInfo(
        new flatbuffers.ByteBuffer(bytes),
      ),
    );
  }

  asBytes(): Uint8Array {
    const builder = new flatbuffers.Builder();

    builder.finish(this.finish(builder));

    return builder.asUint8Array();
  }

  finish(builder: flatbuffers.Builder): number {
    const name = builder.createString(this.name);
    const operatingSystem = builder.createString(this.operatingSystem);

    const [deviceIdLow, deviceIdHigh] = deviceIdToLowHigh(this.deviceId);

    return idl.BridgeDeviceInfo.createBridgeDeviceInfo(
      builder,
      idl.BridgeDeviceId.createBridgeDeviceId(
        builder,
        deviceIdLow,
        deviceIdHigh,
      ),
      name,
      BRIDGE_DEVICE_REVERSE_PLATFORM_MAPPING[this.platform],
      operatingSystem,
    );
  }
}

function deviceIdToLowHigh(deviceId: string): [bigint, bigint] {
  const deviceIdBytes = new DataView(uuidToBytes(deviceId).buffer);

  return [
    BigInt(deviceIdBytes.getUint32(0, true)) +
      4294967296n * BigInt(deviceIdBytes.getUint32(4, true)),
    BigInt(deviceIdBytes.getUint32(8, true)) +
      4294967296n * BigInt(deviceIdBytes.getUint32(12, true)),
  ];
}

function lowHighToDeviceId(low: bigint, high: bigint): string {
  const deviceIdBytes = new DataView(new ArrayBuffer(16));

  deviceIdBytes.setUint32(0, Number(low % 4294967296n), true);
  deviceIdBytes.setUint32(4, Number(low / 4294967296n), true);
  deviceIdBytes.setUint32(8, Number(high % 4294967296n), true);
  deviceIdBytes.setUint32(12, Number(high / 4294967296n), true);

  return bytesToUuid(new Uint8Array(deviceIdBytes.buffer));
}
