import * as flatbuffers from "flatbuffers";
import * as flexbuffers from "flatbuffers/ts/flexbuffers";

import bridge from "@/feature-bridge/bridge.mjs";
import { BufferedQueue } from "@/feature-bridge/buffered.mjs";
import * as idl from "@/feature-bridge/channel-lol-lcu-event-idl.mjs";
import type { IConnection } from "@/feature-bridge/connection.mjs";
import {
  EVENT_LCU,
  // EVENT_LCU_DISCONNECT,
  events as lolEvents,
} from "@/game-lol/utils/lol-client.mjs";
import { devDebug, devError } from "@/util/dev.mjs";

type LcuListener = { connection: IConnection; events: Set<string> };

const listeners: Map<string, LcuListener> = new Map();
const subscriptions: Map<string, Set<string>> = new Map();

bridge.on("disconnected", ({ connection }) => {
  const listener = listeners.get(connection.device.deviceId);

  if (!listener) return;

  listeners.delete(connection.device.deviceId);

  for (const sub of listener.events) {
    subscriptions.get(sub)?.delete(connection.device.deviceId);
  }
});

bridge.openRpcChannel<Set<string>, Set<string>>({
  name: "game_lol/LCU/listen",

  params: {
    // Parameters are a list of strings delimited by newlines
    fromBytes: (bytes) => new Set(new TextDecoder().decode(bytes).split("\n")),
  },

  result: {
    toBytes: (uris) => new TextEncoder().encode([...uris].join("\n")),
  },

  handler: (connection, events) => {
    devDebug(`[channel-lol] Subscribing to ${events.size} events`, {
      deviceId: connection.device.deviceId,
      events,
    });

    let listener = listeners.get(connection.device.deviceId);

    if (!listener) {
      listener = { connection, events };

      listeners.set(connection.device.deviceId, listener);
    } else {
      // Remove old subscriptions
      for (const event of listener.events) {
        subscriptions.get(event)?.delete(connection.device.deviceId);
      }

      listener.events = events;
    }

    // Add new subscriptions
    for (const event of events) {
      if (!subscriptions.has(event)) {
        subscriptions.set(event, new Set());
      }

      subscriptions.get(event)?.add(connection.device.deviceId);
    }

    return events;
  },
});

const lcuMessageChannel = bridge.openMessageChannel({
  name: "game_lol/LCU",
});

// lolEvents.on(EVENT_LCU_DISCONNECT, (...args) => {
//   console.log("EVENT_LCU_DISCONNECT", args);
// });

type LCUEvent = { eventType: string; uri: string; data: unknown };
type LCUEventEncoded = {
  eventType: idl.LcuEventType;
  uri: string;
  encodedData: number[] | Uint8Array;
};

type LCUQueuedEvent = { deviceIds: Set<string>; event: LCUEvent };

const lcuSendQueue = new BufferedQueue<LCUQueuedEvent>({
  onFlush: (entries) => {
    const targets = new Map<IConnection, LCUEventEncoded[]>();

    for (const entry of entries) {
      const event: LCUEventEncoded = {
        eventType: idl.LcuEventType[entry.event.eventType],
        uri: entry.event.uri,
        encodedData: flexbuffers.encode(entry.event.data),
      };

      for (const deviceId of entry.deviceIds) {
        const connection = listeners.get(deviceId)?.connection;

        if (!connection) continue;

        if (!targets.has(connection)) {
          targets.set(connection, []);
        }

        targets.get(connection)?.push(event);
      }
    }

    for (const [connection, events] of targets) {
      try {
        const builder = new flatbuffers.Builder();

        builder.finish(
          idl.LcuEvents.createLcuEvents(
            builder,
            idl.LcuEvents.createEventsVector(
              builder,
              events.map((event) => mapEventToIDL(builder, event)),
            ),
          ),
        );

        const data = builder.asUint8Array();

        lcuMessageChannel.send(connection, data);
      } catch (e) {
        devError("[channel-lol] Failed to send LCU event", e);
      }
    }
  },
});

function mapEventToIDL(builder: flatbuffers.Builder, event: LCUEventEncoded) {
  return idl.LcuEvent.createLcuEvent(
    builder,
    event.eventType,
    builder.createString(event.uri),
    idl.LcuEvent.createDataVector(builder, event.encodedData),
  );
}

lolEvents.on(EVENT_LCU, (event: LCUEvent) => {
  const listeners = subscriptions.get(event.uri);

  if (!listeners) return;

  devDebug(
    `[channel-lol] LCU event ${event.eventType} ${event.uri}`,
    event.data,
  );

  lcuSendQueue.add({ deviceIds: listeners, event });
});
