import { readState } from "@/__main__/app-state.mjs";
import { authTokenHandler } from "@/feature-auth/utils/auth-token-handler.mjs";
import {
  BlitzConnectMessage,
  BlitzConnectMessageCandidate,
  BlitzConnectMessageSignal,
} from "@/feature-bridge/blitzconnect-message.mjs";
import { BridgeDeviceInfoData } from "@/feature-bridge/bridge-device.mjs";
import type { WebRTCConnection } from "@/feature-bridge/connection.mjs";
import { WebRTCConnectionBuilder } from "@/feature-bridge/connection.mjs";
import { devDebug, devWarn } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import { StrictEventEmitter } from "@/util/strict-event-emitter.mjs";

type BlitzConnectEvents = {
  open(): void;
  close(): void;

  connection(connection: WebRTCConnection): void;
};

export default class BlitzConnect extends StrictEventEmitter<BlitzConnectEvents> {
  #tunnelId: Uint8Array;

  #socket: WebSocket;

  #pendingConnection: WebRTCConnectionBuilder;
  #connection: WebRTCConnection;

  get isOpened() {
    return this.#socket && this.#socket.readyState === WebSocket.OPEN;
  }

  async connect() {
    const authToken = await authTokenHandler.getToken();

    if (this.#socket) {
      throw new Error("A socket is already connected");
    }

    this.#tunnelId = globals.crypto.getRandomValues(new Uint8Array(8));

    this.#connection?.close();
    this.#connection = null;

    this.#pendingConnection?.close();
    this.#pendingConnection = null;

    const queryParams = new URLSearchParams();
    queryParams.set("user_id", readState.user?.id);
    queryParams.set("auth_token", authToken);
    this.#socket = new WebSocket(
      new URL(
        `ws://127.0.0.1:3001/${this.#tunnelId.reduce(
          (str, byte) => str + byte.toString(16).padStart(2, "0"),
          "",
        )}?${queryParams}`,
      ),
    );

    let resolveOpened: VoidFunction, rejectOpened: VoidFunction;

    const untilOpened = new Promise<void>((resolve, reject) => {
      resolveOpened = resolve;
      rejectOpened = reject;
    });

    const onOpenResolve = () => {
      devDebug("[blitzconnect] Tunnel opened");

      this.#socket.removeEventListener("close", onCloseReject);

      resolveOpened();

      this.emit("open");
    };

    const onCloseReject = (event: CloseEvent) => {
      devDebug("[blitzconnect] Failed to open tunnel", {
        code: event.code,
        reason: event.reason,
      });

      rejectOpened();
    };

    this.#socket.addEventListener("open", onOpenResolve);
    this.#socket.addEventListener("close", onCloseReject);

    this.#socket.addEventListener("close", (event) => {
      devDebug("[blitzconnect] Tunnel closed", {
        code: event.code,
        reason: event.reason,
      });

      this.emit("close");
    });

    this.#socket.addEventListener("message", async (event) => {
      if (!(event.data instanceof Blob)) {
        devWarn(
          "[blitzconnect] Received non-binary message from server",
          event.data,
        );

        return;
      }

      this.onReceiveEvent((await event.data.stream().getReader().read()).value);
    });

    await untilOpened;
  }

  close() {
    this.#socket?.close();

    this.#socket = null;
    this.#tunnelId = null;
  }

  async generateQRCode(): Promise<string> {
    const userIdHash = await globals.crypto.subtle.digest(
      { name: "SHA-1" },
      new TextEncoder().encode(readState.user.id),
    );

    const { default: QRCode } = await import("qrcode");

    return QRCode.toDataURL(
      [
        {
          mode: "byte",
          data: new TextEncoder().encode("BlitzConnect"),
        },
        {
          mode: "byte",
          data: new Uint8Array(userIdHash),
        },
        {
          mode: "byte",
          data: Uint8Array.from(this.#tunnelId),
        },
      ],
      {
        type: "image/webp",
        margin: 2,
      },
    );
  }

  async sendMessage(msg: BlitzConnectMessage) {
    await this.#socket.send(msg.asBytes());
  }

  async onReceiveEvent(bytes: Uint8Array) {
    // The first byte is the message type
    const eventType = bytes[0];

    // // The next 2 bytes are the client id
    // const clientId = bytes.slice(1, 3);

    // Connect
    if (eventType === 0) {
      this.#connection?.close();
      this.#connection = null;

      this.#pendingConnection?.close();
      this.#pendingConnection = null;

      this.#pendingConnection = WebRTCConnectionBuilder.create();

      const offerSdp = await this.#pendingConnection.createOffer();

      devDebug("[blitzconnect] Detected new connection, sending signal...");

      await this.sendMessage(
        new BlitzConnectMessageSignal({
          deviceInfo: new BridgeDeviceInfoData({
            deviceId: readState.bridge.device.deviceId,
            name: readState.bridge.device.name,
            platform: readState.bridge.device.platform,
            operatingSystem: readState.bridge.device.operatingSystem,
          }),
          sdp: offerSdp,
        }),
      );

      // Disconnect
    } else if (eventType === 1) {
      this.#connection?.close();
      this.#connection = null;

      this.#pendingConnection.close();
      this.#pendingConnection = null;

      // Message
    } else if (eventType === 2) {
      const data = bytes.slice(3);

      await this.onReceiveMessage(data);
    } else {
      devWarn("[blitzconnect] Received unknown event", bytes);
    }
  }

  async onReceiveMessage(data: Uint8Array) {
    const message = BlitzConnectMessage.parse(data);

    if (message instanceof BlitzConnectMessageSignal) {
      if (this.#connection !== null) {
        devWarn(
          `[blitzconnect] Received signal when they've already been exchanged, ignoring`,
        );

        return;
      }

      const pendingConnection =
        this.#pendingConnection ?? WebRTCConnectionBuilder.create();
      pendingConnection.deviceId = message.deviceInfo.deviceId;

      const connection = (this.#connection = pendingConnection.finalize());

      // If we didn't have a pending connection before receiving the signal, send an answer.
      // Otherwise, we already sent an offer when we received the connect event.
      if (this.#pendingConnection === null) {
        const answerSdp = await connection.createAnswer(message.sdp);

        devDebug("[blitzconnect] Received signal, sending answer...");

        await this.sendMessage(
          new BlitzConnectMessageSignal({
            deviceInfo: new BridgeDeviceInfoData({
              deviceId: readState.bridge.device.deviceId,
              name: readState.bridge.device.name,
              platform: readState.bridge.device.platform,
              operatingSystem: readState.bridge.device.operatingSystem,
            }),
            sdp: answerSdp,
          }),
        );
      } else {
        this.#pendingConnection = null;

        connection.onReceivedAnswer(message.sdp);
      }

      const trickleCandidates = async (candidate: RTCIceCandidate) => {
        devDebug(`[blitzconnect] Found candidate, notifying peer`);

        await this.sendMessage(
          new BlitzConnectMessageCandidate({
            candidate: candidate.candidate,
            sdpMLineIndex: candidate.sdpMLineIndex,
            sdpMid: candidate.sdpMid,
          }),
        );
      };

      connection.on("candidate", trickleCandidates);

      connection.on("ready", () => {
        devDebug("[blitzconnect] Connection established");

        this.#connection = null;
        this.#pendingConnection = null;

        connection.off("candidate", trickleCandidates);

        this.emit("connection", connection);
      });
    } else if (message instanceof BlitzConnectMessageCandidate) {
      if (this.#connection === null) {
        devWarn("Received candidate, but no connection exists");

        return;
      }

      devDebug(
        `[blitzconnect] Received candidate, adding to pending connection`,
      );

      await this.#connection.onReceivedCandidate({
        candidate: message.candidate,
        sdpMid: message.sdpMid,
        sdpMLineIndex: message.sdpMLineIndex,
      });
    }
  }
}
