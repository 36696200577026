import "@/feature-bridge/channel-lol-lcu-event.mjs";
import "@/feature-bridge/channel-lol-lcu-request.mjs";
import "@/feature-bridge/channel-bridging.mjs";

import { subscribeKey } from "valtio/utils";

import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  readState,
} from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import mainRefs from "@/__main__/refs.mjs";
import type { User } from "@/feature-auth/models/user-model.mjs";
import {
  addConnection,
  ensureDevice,
  showBlitzConnectPopup,
} from "@/feature-bridge/actions.mjs";
import BlitzConnectPopup from "@/feature-bridge/BlitzConnectPopup.jsx";
import bridge from "@/feature-bridge/bridge.mjs";
import {
  handleBridgeIpcMessage,
  notifyOverlayOfUserChange,
} from "@/feature-bridge/bridge-ipc-handler.mjs";
import {
  bridgeKodeRefs,
  disableBridgeKode,
  enableBridgeKode,
} from "@/feature-bridge/bridge-kode.mjs";
import type { BridgeDiscoveryMessageIncoming } from "@/feature-bridge/discovery.mjs";
import Discovery from "@/feature-bridge/discovery.mjs";
import SettingsIcon from "@/inline-assets/settings.svg";
import addInitialPath from "@/util/add-initial-path.mjs";
import { devError } from "@/util/dev.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";
import subscriptionStateDiff from "@/util/subscription-state-diff.mjs";

declare module "@/__main__/app-state.mjs" {
  interface VolatileState {
    blitzConnectPopupVisible?: boolean;
  }
}

let unsubscribeUser: () => void;

let discovery: Discovery | null;

const _contextMenuItem = {
  Icon: SettingsIcon,
  text: "Pair with QR Code",
  onClick: showBlitzConnectPopup,
};

const originals = mapOriginalRefs({
  mainRefComponents: mainRefs.components,
  bridgeKodeRefs,
});

export async function setup() {
  addInitialPath(["bridge"], {
    device: null,

    authorizedDevices: {},

    connections: [],
  });

  await ensureDevice();

  unsubscribeUser = subscribeKey(
    readState,
    "user" as const,
    subscriptionStateDiff(onUserChanged, readState.user),
  );

  if (IS_APP) {
    originals.append({
      mainRefComponents: {
        floatingElementsTop: [BlitzConnectPopup],
      },
    });

    await initEvents;

    originals.set({
      bridgeKodeRefs: {
        onKodeCompleted() {
          discovery = new Discovery();

          discovery.on("connection", (conn) => {
            try {
              addConnection(conn);
            } catch (err) {
              devError("[bridge] Failed to add connection:", err);
            }
          });

          handleMessage(EVENTS.BRIDGE_DISCOVERY_MESSAGE, async (msg) => {
            try {
              await discovery.onReceiveMessage(
                msg as BridgeDiscoveryMessageIncoming,
              );
            } catch (err) {
              devError("[bridge] Failed to handle discovery message:", err);
            }
          });

          if (readState.user?.id) {
            discovery.startListening();
          }
        },
      },
    });

    enableBridgeKode();

    handleMessage(EVENTS.BRIDGE_IPC_MESSAGE, handleBridgeIpcMessage);
  }
}

export async function teardown() {
  disableBridgeKode();

  originals.restore();

  unsubscribeUser();

  for (const connectionId of bridge.connectedDevices) {
    bridge.closeConnection(connectionId);
  }

  await discovery?.stopListening();
  discovery = null;

  delete writeState.bridge;
}

async function onUserChanged(next?: User, prev?: User) {
  if (next?.id === prev?.id) return;

  for (const connectionId of bridge.connectedDevices) {
    if (bridge.doesRequireAuthentication(connectionId)) {
      bridge.closeConnection(connectionId);
    }
  }

  if (IS_APP) {
    await notifyOverlayOfUserChange(next);
  }

  if (!next?.id) {
    if (IS_APP) {
      await discovery?.stopListening();
    }

    return;
  }

  if (IS_APP) {
    await discovery?.startListening();
  }
}
